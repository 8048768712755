import React from 'react';
import { components } from 'react-select';
import CrossIcon from './images/reg/delete.png'; // replace with your cross icon path
import "./css/customOption.css";

const CustomOption = (props) => {
    const handleClick = (e) => {
        e.stopPropagation(); // Prevent the default option selection behavior
        props.handleDeleteOption(props.data);
    };
    return (
        <components.Option {...props}>
        <div className='CustomOption_Container' >
            <img className='CustomOption_img' src={CrossIcon} alt="X" onClick={handleClick}/>
            {props.data.label}
        </div> 
        </components.Option>
    )
};

export default CustomOption;