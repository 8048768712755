import React, { useState,useRef,useEffect,useContext  } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import DatePicker from "react-datepicker";
import CryptoJS from 'crypto-js';
import CustomOption from './CustomOption.jsx';
import { Button, Navbar, Container, Nav } from 'react-bootstrap';
import jsPDF from 'jspdf';
import Select from 'react-select';
import CreatableSelect from 'react-select';
import Add from './images/reg/add.png';
import Remove from './images/reg/remove.png';
import RegLogo from './images/reg/reglogo.png';
import Vector from './images/reg/Vector.png';
import Search from './images/reg/search.png';
import Owner from './images/reg/owner.png';
import Down from './images/reg/down.png';
import Back from './images/reg/back.png';
import Pdf from './images/reg/pdf.png';
import Cross_nav from './images/reg/cross_nav.png';
import Cross_ID from './images/reg/search_cross.png';
import Employee from './images/reg/Employee.png';
// import ComboBox from 'shersoft-combov1'


import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/Reg_Employee_Details.css";
import "./css/Reg.css";
import "./js/reg.js";
import { faL, faSlash } from '@fortawesome/free-solid-svg-icons';



const customStyles = {
    option: (provided, state) => ({
        ...provided,
        width: '100%',
        backgroundColor: state.isSelected ? '#1B8C4E' : '#FFF', 
        backgroundColor: state.isFocused ? '#1B8C4E' : '#FFF',
        color: state.isFocused ? '#FFF' : '#000',
        ':active': {
            ...provided[':active'],
            backgroundColor: '#1B8C4E' // Change background color when option is active (clicked)
          }
    }),
    input: (provided) => ({
        ...provided,
        width: '100% !important', // Ensure the input field stays at 100%
        '&:hover': {
            cursor: 'text'
        }
      }),
    valueContainer: (provided) => ({
        ...provided,
        overflow: 'hidden', // Prevent input field from expanding
      }),
    control: (provided, state) => ({
        ...provided,
        width: '100% !important',
        fontSize: '1em',
        border: '1px solid #000',
        borderRadius: '0.313rem',
        textAlign: 'left',
        // This line disable the blue border
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #000', // Change the background color on hover
            
        },
        '@media screen and (max-width: 1300px)': {
            fontSize: '0.9em',
        },
        '@media screen and (max-width: 700px)': {
            fontSize: '0.8em',
        },
        '@media screen and (max-width: 400px)': {
            fontSize: '0.7em',
        },

    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '1rem',
        '@media screen and (max-width: 1300px)': {
            fontSize: '0.9em',
        },
        '@media screen and (max-width: 700px)': {
            fontSize: '1.1em',
        },
        '@media screen and (max-width: 400px)': {
            fontSize: '1em',
        },
    }),
    menu: (provided, state) => ({
        ...provided,
        fontSize: '.9rem',
        textAlign: 'left'
    }),
};



function EmployeeReg() {
    const apiBaseUrl = process.env.REACT_APP_API_URL
    const location = useLocation();

    // Assume you have the secret key
    const secretKey = "5059";

    // Get the encrypted username from the URL parameter
    const urlParams = new URLSearchParams(location.search);
    const encryptedUsername = urlParams.get('username');

    let decryptedUsername = '';

    if (encryptedUsername) {
        // Decode the encrypted string
        const decodedEncryptedUsername = decodeURIComponent(encryptedUsername);

        // Decrypt the username
        const decryptedBytes = CryptoJS.AES.decrypt(decodedEncryptedUsername, secretKey);
        decryptedUsername = decryptedBytes.toString(CryptoJS.enc.Utf8);
    }

    const initialFormData = {
        id: "",
        from: "",
        to: "",
        purpose: "",
        years: "" ,
        priority: "",
        bankName: "",
        districtBank: "",
        districtProperty: "",
        branchName: "",
        branchCode: "",
        emNum: "",
        mortName: "",
        borrowName: "",
        accNum1: "",
        accNum2: "",
        accNum3: "",
        name: "",
        houseNo: "",
        postOff: "",
        pinCode: "",
        email: "",
        phoneNum: "",
        mobNum: "",
        documentNum: "",
        year: "",
        bookNum: "",
        hect: "",
        area: "",
        sqrMtre: "",
        acre: "",
        cent: "",
        inch: "",
        sro: "",
        taluk: "",
        village: "",
        desam: "",
        block: "",
        RsNo: "",
        RSSubDivNo: "",
        OldSNo: "",
        OldSubDivNo: "",
        unit: "",
        east: "",
        west: "",
        north: "",
        south: "",
        liabilities: "",
        remark: "",
        declaration: ""
    };
    const [formData, setFormData] = useState(initialFormData);
    const [searchField, setSearchField] = useState({
        search: ""
    })
// .........................................selectoption............................................

// 1
const initialOptions = {
    purpose: [],
    bankName: [],
    district: [],
    village: [],
    desam: [],
    taluk: []
    
};

const [options, setOptions] = useState(initialOptions);
const [inputValues, setInputValues] = useState({
    purpose: '',
    bankName: '',
    districtBank: '',
    districtProperty: '',
    village: '',
    desam: '',
    taluk: ''
    // Add other fields here
});

const selected_option_values = {
    purpose: '',
    bankName: '',
    districtBank: '',
    districtProperty: '',
    village: '',
    desam: '',
    taluk: ''
    // Add other fields here
}
const [selectedValues, setSelectedValues] = useState(selected_option_values);

// const handleInputChange = (newValue, fieldName) => {
//     setInputValues(prevInputValues => ({
//         ...prevInputValues,
//         [fieldName]: newValue
//     }));
// };




const fromInputRef = useRef(null);
const toInputRef = useRef(null);
const purposeInputRef = useRef(null);
const yearsInputRef = useRef(null);
const priorityInputRef = useRef(null);
const bankNameInputRef = useRef(null);
const districtInputRef = useRef(null);
const districtInputRef2 = useRef(null);
const branchNameInputRef = useRef(null);
const branchCodeInputRef = useRef(null);
const emNumInputRef = useRef(null);
const mortNameInputRef = useRef(null);
const borrowNameInputRef = useRef(null);
const accNum1InputRef = useRef(null);
const  accNum2InputRef = useRef(null);
const accNum3InputRef = useRef(null);
const nameInputRef = useRef(null);
const  houseNoInputRef = useRef(null);
const postOffInputRef = useRef(null);
const pinCodeInputRef = useRef(null);
const emailInputRef = useRef(null);
const phoneNumInputRef = useRef(null);
const mobNumInputRef = useRef(null);
const documentNumInputRef = useRef(null);
const yearInputRef = useRef(null);
const bookNumInputRef = useRef(null);
const hectInputRef = useRef(null);
const areaInputRef = useRef(null);
const sqrMtreInputRef = useRef(null);
const sroInputRef = useRef(null);
const talukInputRef = useRef(null);
const villageInputRef = useRef(null);
const desamInputRef = useRef(null);
const blockInputRef = useRef(null);
const RsNoInputRef = useRef(null);
const RSSubDivNoInputRef = useRef(null);
const OldSNoInputRef = useRef(null);
const OldSubDivNoInputRef = useRef(null);
const unitOption1InputRef = useRef(null);
const unitOption2InputRef = useRef(null);
const eastInputRef = useRef(null);
const westInputRef = useRef(null);
const northInputRef = useRef(null);
const southInputRef = useRef(null);
const liabilitiesInputRef = useRef(null);
const remarkInputRef = useRef(null);
const declarationInputRef = useRef(null);
const submit_verify_Ref = useRef(null);
const searchInputRef = useRef(null);

const postOptions = async (name,newOption) => {
    if (name != 'districtBank' & name != 'districtProperty') {
        try {
            await axios.post(`${apiBaseUrl}/select_options/options/${name}`, newOption);
        } catch (error) {
           console.error('Error saving option:', error);
        }
    }
}

const toKeyDownSearch = (e,nextInputFocus) => {
    if (e.key === 'Enter') {
        focusNextInputField(nextInputFocus)
    }
}

const handleKeyDown = (event, name, nextInputFocus) => {
    if (!inputValues[name]) {
        if(event.key == 'Enter'){
            focusNextInputField(nextInputFocus);
        }
        
    } else if (!selectedValues[name]) {
        if (name != 'districtBank' & name != 'districtProperty'){
            if ( event.key ===  'Tab' ) {
            
                const newOption = { label: inputValues[name], value: inputValues[name] }
                postOptions(name,newOption)
                // fetchOptions()
                const getValue = inputValues[name]
                setSelectedValues((prevValues) => ({
                    ...prevValues,
                    [name]: newOption
                }));
                formData[name] = getValue
                
            } else if (event.key ===  'Enter' ) {
                const newOption = { label: inputValues[name], value: inputValues[name] }
                postOptions(name,newOption)
                // fetchOptions()
                const getValue = inputValues[name]
                setSelectedValues((prevValues) => ({
                    ...prevValues,
                    [name]: newOption
                }));
                formData[name] = getValue
                
                focusNextInputField(nextInputFocus);
            }
        } else {
            if(event.key ===  'Enter'){
                focusNextInputField(nextInputFocus);
            }
        }
        // fetchOptions()
    } 
    // fetchOptions()
    
};

const focusNextInputField = (nextInputFocus) => {
    if (nextInputFocus == accNum2InputRef) {
        if (addAccNum) {
            nextInputFocus.current.focus();
        } else {
            nameInputRef.current.focus();
        }
    } else if (nextInputFocus == accNum3InputRef) {
        if (addAccNum2) {
            nextInputFocus.current.focus();
        } else {
            nameInputRef.current.focus();
        }
    } else {
        nextInputFocus.current.focus();
    } 
} 

const focusNextRadio = (nextInputFocus) => {
    if (nextInputFocus.current) {
        nextInputFocus.current.focus();
    }
};

const handleRadioClick = (unit, selectedFieldRef) => {
    handleRadioChange(unit);
    focusNextRadio(selectedFieldRef);
};
// ....................................................................................................need
const fetchOptions = async () => {
    try {
        const fieldNames = Object.keys(initialOptions);
        const fetchPromises = fieldNames.map(fieldName => axios.get(`${apiBaseUrl}/select_options/options/${fieldName}`));
        const responses = await Promise.all(fetchPromises);
        const newOptions = {};
        responses.forEach((response, index) => {
            newOptions[fieldNames[index]] = response.data;
        });
        setOptions(newOptions);
        //console.log(newOptions);
    } catch (error) {
        console.error('Error fetching options:', error);
    }
};
useEffect(() => {
    // Fetch options for each field from the database on component mount
    fetchOptions();
}, []);
//...............

const handleChange = (selectedOption, name, nextField) => {
    setSelectedValues((prevValues) => ({
        ...prevValues,
        [name]: selectedOption,
    }));
    setFormData((prevValues) => ({
        ...prevValues,
        [name]: selectedOption.label ? selectedOption.label : selectedOption,
    }));
    focusNextInputField(nextField)
};

const handleInputChange = (newValue, name) => {
    setInputValues((prevValues) => ({
        ...prevValues,
        [name]: newValue,
    }));
    // setSelectedValues((prevValues) => ({
    //     ...prevValues,
    //     [name]: newValue,
    // }));
    
};

const handleFocus = (fieldName) => {

    setInputValues(prevInputValues => ({
        ...prevInputValues,
        [fieldName]: selectedValues[fieldName]?.label || inputValues[fieldName]
    }));
    setSelectedValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
    }));
};

const handleBlur = (name) => {
    if (name !== 'districtBank' && name !== 'districtProperty') {
        const newOption = { label: inputValues[name], value: inputValues[name] }
    postOptions(name,newOption)
    const getValue = inputValues[name]
    // setSelectedValues((prevValues) => ({
    //     ...prevValues,
    //     [name]: newOption || selectedValues[name]?.label
    // }));
    selectedValues[name] = newOption
    formData[name] = getValue
    }
    
}

// ............................................................................................


    const [error, setError] = useState(false);

    const [addAccNum, setAddAccNum] = useState('')
    const handleOnClickAdd = () => {
        setAddAccNum(prevState => !prevState);
    };
    const [addAccNum2, setAddAccNum2] = useState('')
    const handleOnClickAdd2 = () => {
        setAddAccNum2(prevState => !prevState);
    };

    const handleField = (event, fieldName) => {
        let value;
        // Check if the event contains a label (for dropdown fields) or a value (for input fields)
        if (event.label) {
            value = event.label;
        } else {
            value = event.target.value;
        }
        //console.log(value);
        switch (fieldName) {
            case 'year':
                if (value < 1900 || value > new Date().getFullYear()) {
                    setError(true);
                } else {
                    setError(false);
                }
                break;
            default:
                break;
        }

        // Update the form data
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: value
        }));
        //console.log(formData);
    };

    const [regForm, setRegForm] = useState(true)
    const handleAddBtn = () => {
        setBranchLetterPage(false)
        setSearchDelete(false)
        setSearchModify(false)
        setSearched_ID(false)
        setFormData(initialFormData); // Reset formData to its initial values
        setRegForm(true)
        setSearchField('')
        setShowSideNav(false)
        setInputValues('')
        setApplicationIDs(false)
        setVerifiedIDs(false)
    }

    const [searchModify, setSearchModify] = useState(false)
    const info_modify = "modify"
    const handleModify = async () => {
        const searchTerm = searchField.search;
        if (typeof searchTerm !== 'string' || searchTerm.trim() === "") { // Check if searchField.search is empty or contains only spaces
            alert("Please enter your Application_ID");
        } else {
            try {
                const response = await axios.get(
                    `${apiBaseUrl}/form_info/${searchField.search}`
                );
                setBranchLetter(false)
    
                if (response.status === 200) {
                    setVerifyState(true)
                    setApplicationIDs(false)
                    setShowSideNav(false)
                    const foundData = response.data[0];
                    setSearchModify(true)
                    const mod_data = {
                            uniqueCode: foundData.Unique_Code,
                            id: foundData.id,
                            unit: foundData.Unit,
                            accNum1: foundData.Account_Number1,
                            accNum2: foundData.Account_Number2,
                            accNum3: foundData.Account_Number3,
                            area: foundData.Area,
                            block: foundData.Block,
                            bankName: foundData.Bank_Name,
                            bookNum: foundData.Book_Number,
                            borrowName: foundData.Borrower_Name,
                            branchCode: foundData.Branch_Code,
                            branchName: foundData.Branch_Name,
                            declaration: foundData.Declaration,
                            desam: foundData.Desam,
                            districtBank: foundData.DistrictBank||"",
                            districtProperty: foundData.DistrictProperty||"",
                            documentNum: foundData.Document_Number,
                            emNum: foundData.EM_Number,
                            east: foundData.East,
                            email: foundData.Email_Id,
                            hect: foundData.Hector,
                            houseNo: foundData.House_No,
                            mortName: foundData.Mortagaor_Name.trim(),
                            mobNum: foundData.Mobile_Number,
                            name: foundData.Name.trim(),
                            north: foundData.North.trim(),
                            OldSNo: foundData.OLD_S_No,
                            OldSubDivNo: foundData.OLS_SUB_Div_No,
                            phoneNum: foundData.Phone_Number,
                            pinCode: foundData.Pincode,
                            postOff: foundData.Post_Office.trim(),
                            RsNo: foundData.RS_No,
                            RSSubDivNo: foundData.RS_Sub_Div_No,
                            remark: foundData.Remark.trim(),
                            sro: foundData.SRO,
                            south: foundData.South,
                            sqrMtre: foundData.Sq_mtr,
                            taluk: foundData.Taluk.trim(),
                            village: foundData.Village.trim(),
                            west: foundData.West.trim(),
                            from: foundData.from.split("T")[0],
                            priority: foundData.prtry.trim(),
                            purpose: foundData.purpose.trim(),
                            to: foundData.to.split("T")[0],
                            year: foundData.year,
                            acre: foundData.acre,
                            cent: foundData.cent,
                            inch: foundData.inch,
                            liabilities: foundData.liabilities
                    }
                    const transformedItemMod = transformItem(mod_data);
                    setFormData(transformedItemMod)
                    //console.log(selectedValues);
                    setRegForm(true)
                    setSearched_ID(false)
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        'bankName': foundData.Bank_Name,
                        'purpose' : foundData.purpose,
                        'districtBank' : foundData.District_Bank,
                        'districtProperty' : foundData.District_Property,
                        'village' : foundData.Village,
                        'desam' : foundData.Desam,
                        'taluk' : foundData.Taluk
                    }));
                    setSelectedValues((prevValues) => ({
                        ...prevValues,
                        'districtBank' : foundData.District_Bank,
                        'districtProperty' : foundData.District_Property,
                    }));
                    
                    //console.log(foundData.District_Bank);
                    
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    alert("No data found for the provided ID");
                    //console.log(error.response.data.message);
                } else {
                    console.error("Error:", error.message);
                }
    
            }
        }
        
    };

    const [searchDelete, setSearchDelete] = useState(false)

    useEffect(() => {
        const customDeleteBtn = () => {
            if (typeof searchField.search === 'string' && searchField.search.trim().length > 0) {
                setSearchDelete(true);
            } else {
                setSearchDelete(false);
            }
        };
        customDeleteBtn();
    }, [searchField]);
    

    const handleDelete = async () => {
        const searchTerm = searchField.search;
        if (typeof searchTerm !== 'string' || searchTerm.trim() === "") { // Check if searchField.search is empty or contains only spaces
            alert("Please enter your Application_ID");
        } else {
            const userConfirmed = window.confirm("Are you sure you want to delete this entry?");
            if (userConfirmed) {
                try {
                    if (searchField.search !== '') {
                        const response = await axios.delete(
                            `${apiBaseUrl}/delete_info/${searchField.search}`
                        );
                        setBranchLetter(false)
                        //console.log(response);
                        if (response.status === 200) {
                            setApplicationIDs(false)
                            setShowSideNav(false)
                            setSearchField('')
                            setSearchModify(false)
                            setRegForm(true)
                            setFormData(initialFormData)
                            setSearched_ID(false)
                            setInputValues('')
                            alert(response.data.message);
                        } else {
                            alert(response.data.message);
                        }
                    }
                } catch (error) {
                    console.error("Error:", error.message);
                }
            } else {
                alert("No data found for the provided ID");
            }
        }
    };

    const [errorFrom,setErrorFrom] = useState(false)
    const [errorTo,setErrorTo] = useState(false)
    const [verifyState,setVerifyState] = useState(false)

    const handleSubmit = async () => {
    if (error || formData.from == '' || formData.to == '' || formData.bankName == '' || formData.branchCode == '') {
        alert('Please fill the required entry!')
    } else {
        if (verifyState) {
            const userConfirmed = window.confirm("Do you want to Verify this entry?");
            if (userConfirmed) {
            try {
                // Make a POST request to your server with the form data
                const response = await axios.post(
                    `${apiBaseUrl}/appinfo_verify`,
                    formData
                );

                // Check the response status and handle accordingly
                if (response.status === 200) {
                    alert("Data Verified.")
                    
                    setSearchModify(false)
                    setBranchLetter(false)
                    setShowSideNav(false)
                    setVerifyState(false)
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        'bankName': '',
                        'purpose' : '',
                        'districtBank' : "",
                        'districtProperty' : "",
                        'village' : '',
                        'desam' : '',
                        'taluk' : ''
                    }));
                    setFormData(initialFormData)
                    setSelectedValues(selected_option_values)
                    setSearchField('')
                    try {
                        const response = await axios.delete(
                            `${apiBaseUrl}/after_verify_delete_info/${formData.id}`
                        );
                    } catch (error) {
                        console.error("Error:", error.message);
                    }
                    // Scroll to the header element
                    const header = document.getElementById('header');
                    if (header) {
                        header.scrollIntoView({ behavior: 'smooth' });
                    }

                } else {
                    // Handle other response statuses or errors
                    console.error("Error submitting data:", response.data);
                }

                } catch (error) {
                    // Handle network errors or other exceptions
                    console.error("Error:", error.message);
                }
                }
        } else {
            const userConfirmed = window.confirm("Do you want to Submit this entry?");
            if (userConfirmed) {
            try {
                // Make a POST request to your server with the form data
                const response = await axios.post(
                    `${apiBaseUrl}/appinfo`,
                    formData
                );
                //console.log(formData.from);

                // Check the response status and handle accordingly
                if (response.status === 200) {
                    setSearchModify(false)
                    setBranchLetter(false)
                    setShowSideNav(false)
                    alert("Data Submitted.")
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        'bankName': '',
                        'purpose' : '',
                        'districtBank' : "",
                        'districtProperty' : "",
                        'village' : '',
                        'desam' : '',
                        'taluk' : ''
                    }));
                    setFormData(initialFormData)
                    setSelectedValues(selected_option_values)
                    setSearchField('')
                    
                    // Scroll to the header element
                    const header = document.getElementById('header');
                    if (header) {
                        header.scrollIntoView({ behavior: 'smooth' });
                    }

                } else {
                    // Handle other response statuses or errors
                    console.error("Error submitting data:", response.data);
                }

                } catch (error) {
                    // Handle network errors or other exceptions
                    console.error("Error:", error.message);
                }
                }
            }
        }  
    };

    const [searched_ID, setSearched_ID] = useState(false)
    const handleSearchByAccountNo = async () => {
        const searchTerm = searchField.search;
        if (typeof searchTerm !== 'string' || searchTerm.trim() === "") { // Check if searchField.search is empty or contains only spaces
            alert("Please enter your Application_ID");
        } else{
            try {
            
                const response = await axios.get(
                    `${apiBaseUrl}/form_info_verified/${searchField.search}`
                );
                
                if (response.status === 200) {
                    setRegForm(false)
                    setSearchField('')
                    const foundData = response.data[0];
                    // //console.log(foundData);
                    if(branchLetterPage){
                        try {
                            const response = await axios.post(`${apiBaseUrl}/update-json-letter`, foundData);
                            if (response.status === 200) {
                                // If the request was successful, fetch updated data
                                fetchJsonDataLetter();
                            } else {
                                console.error("Error finding data:", response.data);
                            }
                        } catch (error) {
                            if (error.response && error.response.status === 400) {
                                // If data already exists, show an alert
                                alert(error.response.data.error);
                            } else {
                                console.error("Error updating JSON data:", error);
                            }
                        }
                    setHome(false)
                    setBranchLetter(true)
                    }else{
                        setShowSideNav(false)
                        setSearched_ID(true)
                        setVerifiedIDs(false)
                        try {
                            const response = await axios.post(`${apiBaseUrl}/update-json`, foundData);
                            if (response.status === 200) {
                                // If the request was successful, fetch updated data
                                fetchJsonData();
                            } else {
                                console.error("Error finding data:", response.data);
                            }
                        } catch (error) {
                            if (error.response && error.response.status === 400) {
                                // If data already exists, show an alert
                                alert(error.response.data.error);
                            } else {
                                console.error("Error updating JSON data:", error);
                            }
                        }
                        setApplicationIDs(false)
                    }
    
                } else {
                    console.error("Error finding data:", response.data);
                }
    
            } catch (error) {
                // Handle network or other errors
                if (error.response && error.response.status === 404) {
                    // No data found
                    alert("No data found");
                } else {
                    console.error("Error:", error.message);
                }
            }
        }
        
    };

    const [jsonData, setJsonData] = useState(null);

    // Function to fetch JSON data
    const fetchJsonData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/get-json/`);
            setJsonData(response.data);
            //console.log(branchLetterForm.Unique_Code);
            //console.log(response.data);
        } catch (error) {
            console.error('Error fetching JSON data:', error);
        }
    };

    const fetchJsonDataLetter = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/get-json-letter/`);
            const jsonLetterData = response.data
            //console.log(jsonLetterData);
            const transformedLetter = transformItem(jsonLetterData);
            //console.log(transformedLetter);
            setBranchLetterForm(transformedLetter);
        } catch (error) {
            console.error('Error fetching JSON data:', error);
        }
    };


    const handleDeleteSearchID = async (id) => {
        try {
            // Filter out the item from React state
            const updatedData = jsonData.filter(item => item.id !== id);
            setJsonData(updatedData); // Update the state first
    
            // Make an HTTP request to delete the item from the server
            const response = await axios.delete(`${apiBaseUrl}/delete-item/${id}`);
            if(response.status == 200){
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    useEffect(() => {
        const deleteFullData = async () => {
            try {
                if (!searched_ID) {
                    // Make an HTTP request to delete the full data in the JSON file
                    await axios.delete(`${apiBaseUrl}/delete-full-data`);
                }
            } catch (error) {
                console.error('Error deleting full data:', error);
            }
        };

        deleteFullData(); // Call the function to delete full data when searched_ID is falsy
    }, [apiBaseUrl,searched_ID]);

    const [userProfile, setUserProfile] = useState(false);
    const handleUserProfile = () => {
        setUserProfile(prevState => !prevState);
        setShowSideNav(false)
    };

    const navigate = useNavigate();
    const handleLogOut = () => {
        navigate(`/`);
    }

    const [certificate, setCertificate] = useState(false)
    const [certItem, setCertItem] = useState(null);

    const transformItem = (item) => {
        // Create a new object to avoid mutating the original item
        const newItem = { ...item };
    
        // Iterate over the properties of the item
        for (const key in newItem) {
          if (newItem.hasOwnProperty(key)) {
            // Check if the value is 0 and set it to null if true
            if (newItem[key] === 0) {
              newItem[key] = null;
            }
          }
        }
    
        return newItem;
      };

    const handleCert = (item) => {
        const transformedItem = transformItem(item);
        setCertItem(transformedItem);
        //console.log(certItem);
        setCertificate(true); // Set certificate to true
        setHome(false)
    }
    
    const printRef1 = useRef(null);
    const printRef2 = useRef(null);
    const printRefLetter = useRef(null);

  const handlePrint = async () => {
    try {
      const pdf = new jsPDF('p', 'mm', 'a4');

      await addContentToPdf(pdf, printRef1);
      pdf.addPage();
      await addContentToPdf(pdf, printRef2);

      pdf.save('download.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const addContentToPdf = async (pdf, ref) => {
    const element = ref.current;
    if (element) {
        try {
            const desiredWidth = 210;
            const canvas = await html2canvas(element, { scrollY: window.scrollY });
            const imgData = canvas.toDataURL('image/png');
            const xOffset = (pdf.internal.pageSize.getWidth() - desiredWidth) / 2; // Calculate horizontal offset to center the content
            pdf.addImage(imgData, 'PNG', xOffset, 0, desiredWidth, pdf.internal.pageSize.getHeight());
        } catch (error) {
            console.error('Error adding content to PDF:', error);
        }
    } else {
        console.error('Element to print not found');
    }
    };

  const handlePrintLetter = async () => {
    try {
      const pdf = new jsPDF('p', 'mm', 'a4');

      await addContentToPdfLetter(pdf, printRefLetter);

      pdf.save('download.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const addContentToPdfLetter = async (pdf, ref) => {
    const element = ref.current;
    if (element) {
      try {
        const canvas = await html2canvas(element, { scrollY: window.scrollY });
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      } catch (error) {
        console.error('Error adding content to PDF:', error);
      }
    } else {
      console.error('Element to print not found');
    }
  };

    const [isHovered, setIsHovered] = useState(false);

    const handleBackBtn = () => {
        setCertificate(false)
        setHome(true)
    }
    const handleBackBtnLetter = () => {
        setBranchLetter(false)
        setHome(true)
    }

    const [selectedUnit, setSelectedUnit] = useState('');

    const handleRadioChange = (unit) => {
        setSelectedUnit(unit);
    };

    const handleKeyDownRadio = (e,nextradio,selectedfield,unit) => {
        if(e.key === 'Enter' || e.key === 'Return'){
            if (selectedUnit) {
                focusNextInputField(selectedfield)
            } else {
                focusNextRadio(nextradio)
            }
            
        } else if (e.key === ' ') {
            e.preventDefault(); // Prevent space from scrolling the page
            handleRadioChange(unit)
            
        }
    }

    const [showSideNav, setShowSideNav] = useState(false);
    const handleToggleSideNav = () => {
        setShowSideNav(!showSideNav);
        setUserProfile(false)
    };

    const [branchLetterForm,setBranchLetterForm] = useState('')
    const [branchLetter,setBranchLetter] = useState(false)
    const [branchLetterPage,setBranchLetterPage] = useState(false)
    const toBranchLetter = () => {
        setSearched_ID(false)
        setShowSideNav(false)
        setRegForm(false)
        setBranchLetterPage(true)
        // setSearched_ID(false)
        // setBLetterPlaceholder(true)
        // setBranchLetter(true)
        setBgColor('#1B8C4E');
        setApplicationIDs(false)
        setVerifiedIDs(false)
    }

    const [bgColor, setBgColor] = useState('');

    const [applicationIDs ,setApplicationIDs] = useState(false)
    const toApplicationIDs = async () => {
        setApplicationIDs(true)
        setVerifiedIDs(false)
        setRegForm(false)
        setBranchLetterPage(false)
        setShowSideNav(false)
        fetchApplicationID()
        setSearched_ID(false)
    }

    const [verifiedIDs ,setVerifiedIDs] = useState(false)
    const toVerifiedIDs = async () => {
        setApplicationIDs(false)
        setVerifiedIDs(true)
        setRegForm(false)
        setBranchLetterPage(false)
        setShowSideNav(false)
        fetchVerifiedID()
        setSearched_ID(false)
    }

    const [uniqueIDs,setUniqueIDs]=useState(false)
    const [uniqueVerifiedIDs,setUniqueVerifiedIDs]=useState(false)
    const [adminDetails,setAdminDetails] = useState([])

    const fetchApplicationID = async ()=>  {
        try {
            const response = await axios.get(`${apiBaseUrl}/get-application-IDs`);
            setUniqueIDs(response.data.data);
        } catch (error) {
            console.error('Error fetching admin details:', error.message);
        }
    }

    const fetchVerifiedID = async ()=>  {
        try {
            const response = await axios.get(`${apiBaseUrl}/get-verified-IDs`);
            setUniqueVerifiedIDs(response.data.data);
        } catch (error) {
            console.error('Error fetching admin details:', error.message);
        }
    }

    const [home,setHome] = useState(true)

    const handleDeleteOption = async (option,fieldName) => {
        const toDeleteOption = option.label 
        try {
            const userConfirmed = window.confirm("Do you want to delete " +toDeleteOption+ "?");
            if (userConfirmed) {
                // Make an HTTP request to delete the specific option
                const response = await axios.delete(`${apiBaseUrl}/delete-specific-option/${fieldName}/${toDeleteOption}`);
                if (response.status == 200) {
                    alert(`Successfully Deleted : ${toDeleteOption}`)
                    fetchOptions()
                }
            }
            
        } catch (error) {
            console.error('Error deleting specific option:', error);
        }
    };

    const [employeeState, setEmployeeState] = useState();

    const handleEmployeeStateBtn = () => {
        const newState = !employeeState;
        setEmployeeState(newState);
        handleEmployeeState(newState);
        
    };

    const handleEmployeeState = async (value) => {
        const employeeStateValue = {
            state: value ? 'true' : 'false'
        };

        try {
            const response = await axios.post(
                `${apiBaseUrl}/saveEmployeeState`,
                employeeStateValue
            );

            if (response.status === 200) {
                //console.log('Employee state saved successfully');
            } else {
                console.error('Failed to save employee state');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    let c;
    const [employeeCheckBtn,setEmployeeCheckBtn] = useState({
        state : ''
    })
    
    const checkValue = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/getEmployeeState`);
                
            if (response.status === 200 && response.data) {
                employeeCheckBtn.state = response.data.state
                //console.log(employeeCheckBtn.state);
            } else {
                console.error('Failed to fetch employee state');
            }
        } catch (error) {
            console.error('Error fetching employee state:', error);
        }
    };

    // useEffect(() => {
    //     // const checkValue = async () => {
    //     //     try {
    //     //         const response = await axios.get(`${apiBaseUrl}/getEmployeeState`);
                    
    //     //         if (response.status === 200 && response.data) {
    //     //             employeeCheckBtn.state = response.data.state
    //     //             //console.log(employeeCheckBtn.state);
    //     //         } else {
    //     //             console.error('Failed to fetch employee state');
    //     //         }
    //     //     } catch (error) {
    //     //         console.error('Error fetching employee state:', error);
    //     //     }
    //     // };
    //     // Set interval to run the checkValue function every few seconds
    //     // const intervalId = 
    //     setInterval(checkValue, 10); // Adjust the interval time as needed (2000 ms = 2 seconds)

    //     // Cleanup function to clear the interval when the component is unmounted
    //     // return () => clearInterval(intervalId);
    // }, [checkValue]);

    const displayFrom = (type,section) => {
        if (type === 'from') {
            let fromDatefrom;
            if (section === 'branchLetter') {
                fromDatefrom = branchLetterForm.from;
            } else if (section === 'certificate') {
                fromDatefrom = certItem.from;
            }
            const fromDate = new Date(fromDatefrom);
            // Extract day, month, and year
            const day = fromDate.getDate();
            const month = fromDate.getMonth() + 1; // Months are zero-based, so we add 1
            const year = fromDate.getFullYear();
            const formattedDay = day < 10 ? '0' + day : day;
            const formattedMonth = month < 10 ? '0' + month : month;
            const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
    
            return formattedDate;
        } else if (type === 'to') {
            let fromDateto;
            if (section === 'branchLetter') {
                fromDateto = branchLetterForm.to;
            } else if (section === 'certificate') {
                fromDateto = certItem.to;
            }
            const fromDate = new Date(fromDateto);
            // Extract day, month, and year
            const day = fromDate.getDate();
            const month = fromDate.getMonth() + 1; // Months are zero-based, so we add 1
            const year = fromDate.getFullYear();
            const formattedDay = day < 10 ? '0' + day : day;
            const formattedMonth = month < 10 ? '0' + month : month;
            const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
    
        return formattedDate;
        }
        
        
    }
    const displayUnitType = (page) => {
        if (page === 'certificate') {
            if (certItem.Hector || certItem.Area || certItem.Sq_mtr) {
                return `(MKS)`
            } else if (certItem.Acre || certItem.Cent || certItem.Inch) {
                return `(FPS)`
            } else {
                return ""
            }
        } else if (page === 'branchLetter') {
            if (branchLetterForm.Hector || branchLetterForm.Area || branchLetterForm.Sq_mtr) {
                return `(MKS)`
            } else if (branchLetterForm.Acre || branchLetterForm.Cent || branchLetterForm.Inch) {
                return `(FPS)`
            } else {
                return ""
            }
        }
        
    }
    const displayValue_HectreAcre = (page) => {
        if (page === 'certificate') {            
            if (certItem.Acre === null && certItem.Hector !== null) {
                return `${certItem.Hector} hect`;
            } else if (certItem.Hector === null && certItem.Acre !== null) {
                return `${certItem.Acre} acre`;
            } else {
                return "Nill";
            }
        } else if (page === 'branchLetter') {
            if (branchLetterForm.Acre === null && branchLetterForm.Hector !== null) {
                return `${branchLetterForm.Hector} hect`;
            } else if (branchLetterForm.Hector === null && branchLetterForm.Acre !== null) {
                return `${branchLetterForm.Acre} acre`;
            } else {
                return "Nill";
            }
        }
    }
    const displayValue_AreaCent = (page) => {
        if (page === 'certificate') {
            if (certItem.Area === null && certItem.Cent !== null) {
                return `${certItem.Cent} cent`;
            } else if (certItem.Cent === null && certItem.Area !== null) {
                return `${certItem.Area} are`;
            } else {
                return "Nill";
            }
        } else if (page === 'branchLetter') {
            if (branchLetterForm.Area === null && branchLetterForm.Cent !== null) {
                return `${branchLetterForm.Cent} cent`;
            } else if (branchLetterForm.Cent === null && branchLetterForm.Area !== null) {
                return `${branchLetterForm.Area} are`;
            } else {
                return "Nill";
            }
        }
    }
    
    const displayValue_InchSq_mtr = (page) => {
        if (page === 'certificate') {
            if (certItem.Inch === null && certItem.Sq_mtr !== null) {
                return `${certItem.Sq_mtr} sq.mtr`;
            } else if (certItem.Sq_mtr === null && certItem.Inch !== null) {
                return `${certItem.Inch} inch`;
            } else {
                return "Nill";
            }
        } else if (page === 'branchLetter') {
            if (branchLetterForm.Inch === null && branchLetterForm.Sq_mtr !== null) {
                return `${branchLetterForm.Sq_mtr} sq.mtr`;
            } else if (branchLetterForm.Sq_mtr === null && branchLetterForm.Inch !== null) {
                return `${branchLetterForm.Inch} inch`;
            } else {
                return "Nill";
            }
        }
    }

    const modifyVerify = () => {
        if (regForm & searchModify) {
            return "Verify"
        } else {
            return "Submit"
        }
    }
    

    return (
        <div className='Container_Reg'>
            {(home) &&
                <div id='header'>
                    <div className='Container_Reg_RegLogo_img'> 
                        <div className='Account_owner'>
                            {/* <div className='EmployeeCreation'>
                                <span>Employee Creation</span>
                                <input className='EmployeeCreation_checkbox' type="checkbox" onChange={handleEmployeeStateBtn}/>
                            </div> */}
                            <img className="Owner_Img" src={Owner} alt=""/>
                            <span onClick={handleUserProfile}>{decryptedUsername}</span>
                            <img className="Down_Img" src={Down} alt="" onClick={handleUserProfile}/>
                        </div>
                        <img className="RegLogo_img" src={RegLogo} alt=""/>
                        {userProfile &&
                            <div className={`user_Logout ${userProfile ? 'user_Logout_show' : 'user_Logout_hide'}`}>
                                <button onClick={handleLogOut}>Log out</button>
                            </div>
                        }
                    </div>
                    
                    <div className='Container_Reg_Search_Modify_Application'>
                        <div className='Container_Reg_Search'>
                            <img className="Vector_img" src={showSideNav ? Cross_nav : Vector} alt="?" onClick={handleToggleSideNav}/>
                            {showSideNav && (
                                <div className={`sideNav ${showSideNav ? 'show' : 'hide'}`}>
                                    <div className='reports_Div' style={regForm ? { backgroundColor: '#1B8C4E', color: '#fff' } : {}} onClick={() => {
                                        setRegForm(true);
                                        setBranchLetterPage(false);
                                        setApplicationIDs(false);
                                        setShowSideNav(false)
                                        setVerifiedIDs(false)
                                        }}>Home
                                    </div>
                                    <span className='reports_Span'>Reports</span>
                                    <div className='reports_Div' style={branchLetterPage ? { backgroundColor: bgColor, color: '#fff' } : {}} onClick={toBranchLetter}>Branch Letter</div>
                                    <div className='reports_Div' style={applicationIDs ? { backgroundColor: '#1B8C4E', color: '#fff' } : {}} onClick={toApplicationIDs}>Branch Code</div>
                                    <div className='reports_Div_BCode' style={verifiedIDs ? { backgroundColor: '#1B8C4E', color: '#fff' } : {}} onClick={toVerifiedIDs}>Verified ID</div>
                                </div>
                            )}
                            <div className='Search_Div'>
                                <img src={Search} alt="?" />
                                <input type="search" value={searchField.search} placeholder="Application ID..."  onChange={(e) =>
                                    setSearchField({ ...searchField, search: e.target.value })
                                } 
                                onKeyDown={(e) => toKeyDownSearch(e,searchInputRef)}
                                />
                            </div>
                            <button className="Container_Reg_Search_Btn" onClick={handleSearchByAccountNo} ref={searchInputRef}>Search</button>
                        </div>
                        <div className='Container_Reg_Update'>
                            <button className={`Container_Reg_Update_Btn ${(regForm & !searchModify) ? 'Update_Btn' : ''}`} onClick={handleAddBtn}>Add</button>
                            <button className={`Container_Reg_Update_Btn ${(regForm & searchModify) ? 'Update_Btn' : ''}`} onClick={handleModify}>Modify</button>
                            <button className={`Container_Reg_Update_Btn ${searchDelete ? 'Update_Btn_delete' : ''}`} onClick={handleDelete}>Delete</button>
                            <button className="Container_Reg_Update_Btn" onClick={handleSubmit} ref={submit_verify_Ref}>{modifyVerify()}</button>
                        </div>
                    </div>

                    {searched_ID && Array.isArray(jsonData) &&  jsonData.map(item => (
                        <div key={item.id} className='Application_Status_Div'>
                            <div className='Status_Div'>
                                <div className='Status_Div_Name'>
                                    <div className='Status_Div_box' onClick={() => handleDeleteSearchID(item.id)} >
                                        <img src={Cross_ID} alt="" />
                                    </div>
                                    <span>{item.Unique_Code}</span>
                                </div>
                                <div className='Status_Div_View_Print'>
                                    <button className='View_Print' onClick={() => handleCert(item)}>View/Print</button>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* ADD BUTTON */}

                    {(regForm) &&
                        < div className='Container_Reg_Info'>
                            <div className='All_Reg_Section'>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Bank Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Bank Name</span></div>
                                                <div className='TextInput_Input'>
                                                    <Select
                                                    ref={bankNameInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.bankName}
                                                    options={options.bankName}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'bankName',districtInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'bankName')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'bankName',districtInputRef)}
                                                    inputValue={inputValues.bankName}
                                                    onFocus={() => handleFocus('bankName')}
                                                    onBlur={() => handleBlur('bankName')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'bankName')} /> }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>District</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={districtInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.districtBank}
                                                    options={options.district}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'districtBank',branchNameInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'districtBank')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'districtBank',branchNameInputRef)}
                                                    inputValue={inputValues.districtBank}
                                                    onFocus={() => handleFocus('districtBank')}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Branch Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.branchName} onChange={(e) => handleField(e, 'branchName')} 
                                                    ref={branchNameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'branchName',branchCodeInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Branch Code</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.branchCode} onChange={(e) => handleField(e, 'branchCode')} 
                                                    ref={branchCodeInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'branchCode',emNumInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>EM Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.emNum} onChange={(e) => handleField(e, 'emNum')} 
                                                    ref={emNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'emNum',mortNameInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Mortagaor Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.mortName} onChange={(e) => handleField(e, 'mortName')} 
                                                    ref={mortNameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'mortName',borrowNameInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Borrower Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.borrowName} onChange={(e) => handleField(e, 'borrowName')} 
                                                    ref={borrowNameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'borrowName',accNum1InputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Account Number</span></div>
                                                <div className='TextInput_Input TextInput_Input_Acc'>
                                                    {!addAccNum && <img src={Add} alt="?" className='Add_Img' onClick={handleOnClickAdd} />}
                                                    <input min="0" type="number" className={!addAccNum ? 'AccNum_AddImg' : ''} value={formData.accNum1} onChange={(e) => handleField(e, 'accNum1')} 
                                                    ref={accNum1InputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'accNum1',accNum2InputRef)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={addAccNum ? 'Div_Tail_Row' : 'Div_Tail_Row_None'}>
                                            <div className='TextInput'>
                                                {addAccNum &&
                                                    <div className='TextInput_Text'><span>Account Number 2</span></div>
                                                }
                                                {addAccNum &&
                                                    <div className='TextInput_Input TextInput_Input_Acc'>
                                                        {!addAccNum2 && <img src={Add} alt="?" className='Add_Img' onClick={handleOnClickAdd2} />}
                                                        <input min="0" type="number" className={!addAccNum2 ? 'AccNum_AddImg' : ''} value={formData.accNum2} onChange={(e) => handleField(e, 'accNum2')} 
                                                    ref={accNum2InputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'accNum2',accNum3InputRef)}/>
                                                    </div>
                                                }
                                            </div>
                                            <div className='TextInput'>
                                                {addAccNum2 &&
                                                    <div className='TextInput_Text'><span>Account Number 3</span></div>
                                                }
                                                {addAccNum2 &&
                                                    <div className='TextInput_Input TextInput_Input_Acc'>
                                                        <img src={Remove} alt="?" className='Add_Img' onClick={handleOnClickAdd2} />
                                                        <input min="0" type="number" className="AccNum3_AddImg" value={formData.accNum3} onChange={(e) => handleField(e, 'accNum3')} 
                                                    ref={accNum3InputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'accNum3',nameInputRef)}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div >

                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Application Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.name} onChange={(e) => handleField(e, 'name')} 
                                                    ref={nameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'name',houseNoInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>House Name/No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.houseNo} onChange={(e) => handleField(e, 'houseNo')} 
                                                    ref={houseNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'houseNo',postOffInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Post Office</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.postOff} onChange={(e) => handleField(e, 'postOff')} 
                                                    ref={postOffInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'postOff',pinCodeInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Pincode</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.pinCode} onChange={(e) => handleField(e, 'pinCode')} 
                                                    ref={pinCodeInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'pinCode',emailInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Email Id</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.email} onChange={(e) => handleField(e, 'email')} 
                                                    ref={emailInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'email',phoneNumInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Phone Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.phoneNum} onChange={(e) => handleField(e, 'phoneNum')} 
                                                    ref={phoneNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'phoneNum',mobNumInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Mobile Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.mobNum} onChange={(e) => handleField(e, 'mobNum')} 
                                                    ref={mobNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'mobNum',documentNumInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Document Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Document Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.documentNum} onChange={(e) => handleField(e, 'documentNum')} 
                                                    ref={documentNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'documentNum',yearInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Year</span></div>
                                                <div className='TextInput_Input'>
                                                    <input
                                                    ref={yearInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'year',bookNumInputRef)}
                                                        className={`TextInput_Input Input ${error ? 'error' : ''}`}
                                                        type="number"
                                                        min="1900"
                                                        max={new Date().getFullYear()}
                                                        value={formData.year}
                                                        onChange={(e) => handleField(e, 'year')}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Book Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.bookNum} onChange={(e) => handleField(e, 'bookNum')} 
                                                    ref={bookNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'bookNum',sroInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Property Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>SRO</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.sro} onChange={(e) => handleField(e, 'sro')} 
                                                    ref={sroInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'sro',districtInputRef2)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>District</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={districtInputRef2}
                                                    styles={customStyles}
                                                    value={selectedValues.districtProperty}
                                                    options={options.district}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'districtProperty',talukInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'districtProperty')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'districtProperty',talukInputRef)}
                                                    inputValue={inputValues.districtProperty}
                                                    onFocus={() => handleFocus('districtProperty')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Taluk</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={talukInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.taluk}
                                                    options={options.taluk}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'taluk',villageInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'taluk')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'taluk',villageInputRef)}
                                                    inputValue={inputValues.taluk}
                                                    onFocus={() => handleFocus('taluk')}
                                                    onBlur={() => handleBlur('taluk')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'taluk')} /> }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Village</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={villageInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.village}
                                                    options={options.village}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'village',desamInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'village')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'village',desamInputRef)}
                                                    inputValue={inputValues.village}
                                                    onFocus={() => handleFocus('village')}
                                                    onBlur={() => handleBlur('village')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'village')} /> }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Desam</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={desamInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.desam}
                                                    options={options.desam}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'desam',blockInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'desam')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'desam',blockInputRef)}
                                                    inputValue={inputValues.desam}
                                                    onFocus={() => handleFocus('desam')}
                                                    onBlur={() => handleBlur('desam')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'desam')} /> }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Block</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.block} onChange={(e) => handleField(e, 'block')} 
                                                    ref={blockInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'block',RsNoInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>RS No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.RsNo} onChange={(e) => handleField(e, 'RsNo')} 
                                                    ref={RsNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'RsNo',RSSubDivNoInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>RS Sub Div No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.RSSubDivNo} onChange={(e) => handleField(e, 'RSSubDivNo')} 
                                                    ref={RSSubDivNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'RSSubDivNo',OldSNoInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>OLD S No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.OldSNo} onChange={(e) => handleField(e, 'OldSNo')} 
                                                    ref={OldSNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'OldSNo',OldSubDivNoInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>OLS SUB Div No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.OldSubDivNo} onChange={(e) => handleField(e, 'OldSubDivNo')} 
                                                    ref={OldSubDivNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'OldSubDivNo',unitOption1InputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Unit</span></div>
                                                <div className='TextInput_Radio'>
                                                    <div className='TextInput_Input_Checkbox'>
                                                        <span>MKS</span>
                                                        <input type="radio"
                                                        onClick={() => handleRadioClick('MKS', hectInputRef)}
                                                        onKeyDown={(event) => handleKeyDownRadio(event,unitOption2InputRef,unitOption2InputRef,'MKS')}
                                                        ref={unitOption1InputRef} checked={selectedUnit === 'MKS'} />
                                                    </div>
                                                    <div className='TextInput_Input_Checkbox'>
                                                        <span>FPS</span>
                                                        <input type="radio" 
                                                        onClick={() => handleRadioClick('FPS', hectInputRef)}
                                                        onKeyDown={(event) => handleKeyDownRadio(event,eastInputRef,hectInputRef,'FPS')}
                                                        ref={unitOption2InputRef} checked={selectedUnit === 'FPS'} />
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedUnit === 'MKS' && 
                                                <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Hectre/Area/Sq.Mtr</span></div>
                                                <div className='TextInput_Input'>
                                                    <div className='TextInput_Input_Num'>
                                                        <input min="0" type="number" placeholder="Hectre" value={formData.hect} onChange={(e) => handleField(e, 'hect')} 
                                                    ref={hectInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'hect',areaInputRef)}/>
                                                        <input min="0" type="number" placeholder="Are" value={formData.area} onChange={(e) => handleField(e, 'area')} 
                                                    ref={areaInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'area',sqrMtreInputRef)}/>
                                                        <input min="0" type="number" placeholder="Sq.mtre" value={formData.sqrMtre} onChange={(e) => handleField(e, 'sqrMtre')} 
                                                    ref={sqrMtreInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'sqrMtre',eastInputRef)}/>
                                                    </div>
                                                </div>
                                                </div>
                                            }
                                            {selectedUnit === 'FPS' && 
                                                <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Acre/Cent/Inch</span></div>
                                                <div className='TextInput_Input'>
                                                    <div className='TextInput_Input_Num'>
                                                        <input min="0" type="number" placeholder="Acre" value={formData.acre} onChange={(e) => handleField(e, 'acre')} 
                                                    ref={hectInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'hect',areaInputRef)}/>
                                                        <input min="0" type="number" placeholder="Cent" value={formData.cent} onChange={(e) => handleField(e, 'cent')} 
                                                    ref={areaInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'area',sqrMtreInputRef)}/>
                                                        <input min="0" type="number" placeholder="Inch" value={formData.inch} onChange={(e) => handleField(e, 'inch')} 
                                                    ref={sqrMtreInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'sqrMtre',eastInputRef)}/>
                                                    </div>
                                                </div>
                                                </div>
                                            }
                                            {selectedUnit === '' &&
                                                <div className='TextInput'></div>
                                            }
                                            
                                        </div>
                                    </div >
                                </div >
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Boundry of the Property</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>East</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.east} onChange={(e) => handleField(e, 'east')} 
                                                    ref={eastInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'east',westInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>West</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.west} onChange={(e) => handleField(e, 'west')} 
                                                    ref={westInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'west',northInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>North</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.north} onChange={(e) => handleField(e, 'north')} 
                                                    ref={northInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'north',southInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>South</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.south} onChange={(e) => handleField(e, 'south')} 
                                                    ref={southInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'south',fromInputRef)}/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Search Period</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>From</span></div>
                                                <div className='TextInput_Input'>
                                                    <input
                                                    className={`${errorFrom ? 'errorFromTo' : ''}`}
                                                    ref={fromInputRef}
                                                    type="date"
                                                    value={formData.from}
                                                    onKeyDown={(event) => handleKeyDown(event, 'from',toInputRef)}
                                                    onChange={(e) => handleField(e, 'from')} />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>To</span></div>
                                                <div className='TextInput_Input'><input
                                                    className={`${errorTo ? 'errorFromTo' : ''}`}
                                                    type="date"
                                                    ref={toInputRef}
                                                    value={formData.to}
                                                    onKeyDown={(event) => handleKeyDown(event, 'to',yearsInputRef)}
                                                    onChange={(e) => handleField(e, 'to')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Years</span></div>
                                                <div className='TextInput_Input'><input type="number" value={formData.years} onChange={(e) => handleField(e, 'years')} 
                                                        ref={yearsInputRef}
                                                        onKeyDown={(event) => handleKeyDown(event, 'years',purposeInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Purpose</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={purposeInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.purpose}
                                                    options={options.purpose}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'purpose',priorityInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'purpose')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'purpose',priorityInputRef)}
                                                    inputValue={inputValues.purpose}
                                                    onFocus={() => handleFocus('purpose')}
                                                    onBlur={() => handleBlur('purpose')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'purpose')} /> }}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                        <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Priority</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.priority} onChange={(e) => handleField(e, 'priority')} 
                                                        ref={priorityInputRef}
                                                        onKeyDown={(event) => handleKeyDown(event, 'priority',liabilitiesInputRef)}/></div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Liabilities</div>
                                    <div className='Declation_Tail'>
                                        <textarea type="text" placeholder="Enter here..." rows={1} cols={20} value={formData.liabilities} onChange={(e) => handleField(e, 'liabilities')} 
                                                    ref={liabilitiesInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'remark',remarkInputRef)}/>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Remark</div>
                                    <div className='Declation_Tail'>
                                        <textarea type="text" placeholder="Enter here..." rows={1} cols={20} value={formData.remark} onChange={(e) => handleField(e, 'remark')} 
                                                    ref={remarkInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'remark',declarationInputRef)}/>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Declaration</div>
                                    <div className='Declation_Tail'>
                                        <textarea type="text" placeholder="Enter here..." rows={1} cols={20} value={formData.declaration} onChange={(e) => handleField(e, 'declaration')} 
                                                    ref={declarationInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'declaration',submit_verify_Ref)}/>
                                    </div>
                                </div>
                            </div >
                            {/* <div className='SubmitReset'>
                        <button className='SubmitBtn' onClick={handleSubmit}>Submit Application</button>
                        <button className='ResetBtn'>Reset</button>
                    </div> */}
                        </div>
                    }
                </div>
            }

            {certificate &&
            <div>
                <div className='container_certificate_Btn'>
                    <button className='container_certificate_BackBtn' 
                        onClick={handleBackBtn}><img src={Back} alt="<-" />
                    </button>
                    <button className='container_certificate_DownloadBtn' 
                        onClick={handlePrint}
                        onMouseEnter={() => setIsHovered(true)} 
                        onMouseLeave={() => setIsHovered(false)}><img src={Pdf} alt="?" />
                        <span className={`container_certificate_DownloadBtn_text ${isHovered ? 'show' : 'hide'}`}>Download(PDF)</span>
                    </button>
                </div>
                <div className='container_certificate' ref={printRef1}>
                    <div className='container_certificate_section'>
                        <div className='JP_Title'>
                            <div className='JP_Title_content'>
                                <span className='JP_Title_JPs'>JP's</span>
                                <span className='JP_Title_gramin'>Gramin</span>
                                <span className='JP_Title_em'>EM Services</span>
                                <span className='JP_Title_mob'>Mob:7034271592</span>
                            </div>
                        </div>
                        <span className='container_certificate_section_span'>APPLICATION FOR ENCUMBRANCE CERTIFICATE</span>
                        <div className='certificate_section_tables'>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>Application ID</th>
                                        <td className='tbody_td'>: {certItem.Unique_Code}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className='certificate_section_tables_span'>Bank Details</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>Bank Name</th>
                                        <td className='tbody_td'>: {certItem.Bank_Name}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>District(Bank Situated)</th>
                                        <td className='tbody_td'>: {certItem.District_Bank}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Branch Name</th>
                                        <td className='tbody_td'>: {certItem.Branch_Name}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Branch Code</th>
                                        <td className='tbody_td'>: {certItem.Branch_Code}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>EM Number</th>
                                        <td className='tbody_td'>: {certItem.EM_Number}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Name of Mortgagor</th>
                                        <td className='tbody_td'>: {certItem.Mortagaor_Name}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Borrower Name</th>
                                        <td className='tbody_td'>: {certItem.Borrower_Name}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Account Number</th>
                                        <td className='tbody_td'>: {certItem.Account_Number1}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>District (SRO)</th>
                                        <td className='tbody_td'>: {certItem.District_Property}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>SRO</th>
                                        <td className='tbody_td'>: {certItem.SRO}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className='certificate_section_tables_span'>Application Details</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>Name</th>
                                        <td className='tbody_td'>: {certItem.Name}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>House No</th>
                                        <td className='tbody_td'>: {certItem.House_No}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Post Office</th>
                                        <td className='tbody_td'>: {certItem.Post_Office}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Pincode</th>
                                        <td className='tbody_td'>: {certItem.Pincode}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Email ID</th>
                                        <td className='tbody_td'>: {certItem.Email_Id}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Phone No</th>
                                        <td className='tbody_td'>: {certItem.Phone_Number}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Mobile No</th>
                                        <td className='tbody_td'>: {certItem.Mobile_Number}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='container_certificate' ref={printRef2}>
                    <div className='container_certificate_section'>
                        <div className='certificate_section_tables'>
                            <span className='certificate_section_tables_span'>Document Details</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>Document Number</th>
                                        <td className='tbody_td'>: {certItem.Document_Number}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Year</th>
                                        <td className='tbody_td'>: {certItem.year}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className='certificate_section_tables_span'>Property Details</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>Taluk</th>
                                        <td className='tbody_td'>: {certItem.Taluk}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Village</th>
                                        <td className='tbody_td'>: {certItem.Village}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Desam</th>
                                        <td className='tbody_td'>: {certItem.Desam}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>RS.No</th>
                                        <td className='tbody_td'>: {certItem.RS_No}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>RS.Sub.Div.No</th>
                                        <td className='tbody_td'>: {certItem.RS_Sub_Div_No}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Old Survey No</th>
                                        <td className='tbody_td'>: {certItem.OLD_S_No}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Old Survey Sub Div No</th>
                                        <td className='tbody_td'>: {certItem.OLS_SUB_Div_No}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Area{displayUnitType('certificate')}</th>
                                        <td className='tbody_td'>:
                                            <input readOnly className='tbody_td_area_input' type="text" value={displayValue_HectreAcre('certificate')}/>
                                            <input readOnly className='tbody_td_area_input' type="text" value={displayValue_AreaCent('certificate')}/>
                                            <input readOnly className='tbody_td_area_input' type="text" value={displayValue_InchSq_mtr('certificate')}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className='certificate_section_tables_span'>Boundary of the Document</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>East</th>
                                        <td className='tbody_td'>: {certItem.East}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>West</th>
                                        <td className='tbody_td'>: {certItem.West}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>North</th>
                                        <td className='tbody_td'>: {certItem.North}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>South</th>
                                        <td className='tbody_td'>: {certItem.South}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Remark</th>
                                        <td className='tbody_td'>: {certItem.Remark}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className='certificate_section_tables_span'>Search Period</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>From</th>
                                        <td className='tbody_td'>: {displayFrom('from','certificate')}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>To</th>
                                        <td className='tbody_td'>: {displayFrom('to','certificate')}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Priority</th>
                                        <td className='tbody_td'>: {certItem.prtry}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className='certificate_section_tables_span'>Others</span>
                            <table className='section_tables'>
                                <tbody className='section_tables_tbody'>
                                    <tr>
                                        <th className='tbody_th'>Name of Mortgagor</th>
                                        <td className='tbody_td'>: {certItem.Mortagaor_Name}</td>
                                    </tr>
                                    <tr>
                                        <th className='tbody_th'>Purpose of Certificate</th>
                                        <td className='tbody_td'>: {certItem.purpose}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='JP_Footer'>
                            <div className='JP_Footer_left'>
                                <div className='JP_Footer_left_contents'>
                                    <span className='Footer_left_JPs'>JP's</span>
                                    <span className='Footer_left_gramin'>Gramin</span>
                                    <span className='Footer_left_em'>EM Services</span>
                                </div>
                            </div>
                            <div className='JP_Footer_right'>
                                <div className='JP_Footer_right_contents'>
                                    <span className='Footer_right_JP'>Jayaprakash V S</span>
                                    <span className='Footer_right_po'>Villangattil(H) Vilayur (P.O)</span>
                                    <span className='Footer_right_dt'>Vilayur,Palakkad(Dt)</span>
                                    <span className='Footer_right_pin'>Pin: 679309</span>
                                    <span className='Footer_right_pin'>Mob:7034271592</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        {branchLetter && 
            <div>
                <div className='container_certificate_Btn'>
                    <button className='container_certificate_BackBtn' 
                        onClick={handleBackBtnLetter}><img src={Back} alt="<-" />
                    </button>
                    <button className='container_certificate_DownloadBtn' 
                        onClick={handlePrintLetter}
                        onMouseEnter={() => setIsHovered(true)} 
                        onMouseLeave={() => setIsHovered(false)}><img src={Pdf} alt="?" />
                        <span className={`container_certificate_DownloadBtn_text ${isHovered ? 'show' : 'hide'}`}>Download(PDF)</span>
                    </button>
                </div>
                <div className='container_certificate' ref={printRefLetter}>
                    <div className='container_certificate_section'>
                        <div className='JP_Title-letter'>
                            <div className='JP_Title_content-letter'>
                                <span className='JP_Title_JPs-letter'>JP's</span>
                                <span className='JP_Title_gramin-letter'>Gramin</span>
                                <span className='JP_Title_em-letter'>EM Services</span>
                                <span className='JP_Title_mob-letter'>Mob:7034271592</span>
                                <span className='JP_Title_mob-letter'>Vilayur,Palakkad(Dt)</span>
                                <span className='JP_Title_email-letter'>jpsgraminemservice@gmail.com</span>
                            </div>
                        </div>
                        <div className='BLetter_Contents_Head'>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Application ID</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input value={branchLetterForm.Unique_Code} readOnly type="text" />
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Bank Name</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input value={branchLetterForm.Bank_Name} readOnly type="text" />
                                </div>
                            </div>
                        </div>
                        <div className='BLetter_Contents'>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Branch Code</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input value={branchLetterForm.Branch_Code} readOnly type="number" />
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Branch Name</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input className='BLetter_Contents_spanINput_input_branchName' value={branchLetterForm.Branch_Name} readOnly type="text" />
                                </div>
                            </div>
                        </div>
                        <div className='BLetter_Contents'>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Name of Borrower</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input value={branchLetterForm.Borrower_Name} readOnly type="text" />
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>EM Number</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input className='BLetter_Contents_spanINput_input_em_number' value={branchLetterForm.EM_Number} readOnly type="number" />
                                </div>
                            </div>
                        </div>
                        <div className='BLetter_Contents'>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Loan Account Number</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input value={branchLetterForm.Account_Number1} readOnly type="number" />
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput'>
                                <div className='BLetter_Contents_spanINput_span'>
                                    <span>Name of Mortgagor</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_input'>
                                    <input value={branchLetterForm.Mortagaor_Name} readOnly type="text" />
                                </div>
                            </div>
                        </div>
                        <div className='BLetter_Contents'>
                            <div className='BLetter_Contents_spanINput_flxCol1'>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_first'>
                                    <div className='flxCol1_span'>
                                        <span >Document Number</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.Document_Number} readOnly type="number" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents'>
                                    <div className='flxCol1_span'>
                                        <span>Year</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.year} readOnly type="number" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_last'>
                                    <div className='flxCol1_span'>
                                        <span>SRO</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.SRO} readOnly type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput_flxCol1'>
                                <div className='BLetter_Contents_spanINput_flxCol1_EC_span' >
                                    <span>EC Period</span>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_EC_Yr'>
                                    <div className='BLetter_Contents_spanINput_flxCol1_EC_Yr_head'>
                                        <div className='EC_Yr_head_fromto'>From</div>
                                        <div className='EC_Yr_head_fromto'>To</div>
                                        <div className='EC_Yr_head_year'>Years</div>
                                    </div>
                                    <div className='BLetter_Contents_spanINput_flxCol1_EC_Yr_data'>
                                        <div className='EC_Yr_data_fromto'> {displayFrom('from','branchLetter')} </div>
                                        <div className='EC_Yr_data_fromto'> {displayFrom('to','branchLetter')} </div>
                                        <div className='EC_Yr_data_year'> {branchLetterForm.Years} </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='BLetter_Contents'>
                            <div className='BLetter_Contents_spanINput_flxCol1'>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_first'>
                                    <div className='flxCol1_span'>
                                        <span >Village</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.Village} readOnly type="text" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents'>
                                    <div className='flxCol1_span'>
                                        <span>Block</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.Block} readOnly type="text" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_year'>
                                    <div className='flxCol1_span'>
                                        <span>RS No</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.RS_No} readOnly type="text" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_last'>
                                    <div className='flxCol1_span'>
                                        <span>Rs Sub Div Number</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.RS_Sub_Div_No} readOnly type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput_flxCol1'>
                                <div className='BLetter_Contents_spanINput_flxCol1_liable'>
                                    <span className='BLetter_Contents_spanINput_flxCol1_liable_liable'>Liabilities Noted</span>
                                    <span className='BLetter_Contents_spanINput_flxCol1_liable_small'>(As per EC Report)</span>
                                    <span className='BLetter_Contents_spanINput_flxCol1_liable_text'>{branchLetterForm.Liabilities}</span>
                                </div>
                            </div>
                        </div>
                        <div className='BLetter_Contents'>
                            <div className='BLetter_Contents_spanINput_flxCol1'>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_first'>
                                    <div className='flxCol1_span'>
                                        <span >Old S Number</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.OLD_S_No} readOnly type="text" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents'>
                                    <div className='flxCol1_span'>
                                        <span> Old S Sub Number</span>
                                    </div>
                                    <div className='flxCol1_input'>
                                        <input value={branchLetterForm.OLS_SUB_Div_No} readOnly type="text" />
                                    </div>
                                </div>
                                <div className='BLetter_Contents_spanINput_flxCol1_Contents_year'>
                                    <div className='flxCol1_span'>
                                        <span>Area{displayUnitType('branchLetter')}</span>
                                    </div>
                                    <div className='flxCol1_input_Area'>
                                        <input readOnly type="text" value={displayValue_HectreAcre('branchLetter')}/>
                                        <input readOnly type="text" value={displayValue_AreaCent('branchLetter')} />
                                        <input readOnly type="text" value={displayValue_InchSq_mtr('branchLetter')} />
                                    </div>
                                </div>
                            </div>
                            <div className='BLetter_Contents_spanINput_flxCol1'>
                                {/* <div className='BLetter_Contents_spanINput_flxCol1_liable'>
                                    <div className='BLetter_Contents_spanINput_flxCol1_Survey_texthead'>
                                        <span>SURVEY DETAILS</span>
                                    </div>
                                    <div className='BLetter_Contents_spanINput_flxCol1_Survey_details'>
                                        <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks'>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_head'>Block</div>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_tail'>{branchLetterForm.Block}</div>
                                        </div>
                                        <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks'>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_head'>Desam</div>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_tail'>{branchLetterForm.Desam}</div>
                                        </div>
                                        <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks'>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_head'>S Number</div>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_tail'>{branchLetterForm.OLD_S_No}</div>
                                        </div>
                                        <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks'>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_head'>RS Number</div>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_tail'>{branchLetterForm.RS_No}</div>
                                        </div>
                                        <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks'>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_head'>Extent</div>
                                            <div className='BLetter_Contents_spanINput_flxCol1_Survey_blocks_tail'>-</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className='BLetter_Contents_footer'>
                            <div className='BLetter_Contents_footer_parts'>
                                <div className='footer_parts_left_contents'>
                                    <span className='Footer_left_JPs'>JP's</span>
                                    <span className='Footer_left_gramin'>Gramin</span>
                                    <span className='Footer_left_em'>EM Services</span>
                                </div>
                            </div>
                            <div className='BLetter_Contents_footer_parts'>
                                <div className='footer_parts_right_contents'>
                                    <span className='Footer_right_JP'>Jayaprakash V S</span>
                                    <span className='Footer_right_po'>Villangattil(H) Vilayur (P.O)</span>
                                    <span className='Footer_right_dt'>Vilayur,Palakkad(Dt)</span>
                                    <span className='Footer_right_pin'>Pin: 679309</span>
                                    <span className='Footer_right_pin'>Mob:7034271592</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {applicationIDs && 
                <div className='container_Empl_details' >
                    {Array.isArray(uniqueIDs) && (
                        <table>
                            <thead>
                                <tr className='container_Empl_details_table_tr'>
                                    <th>No.</th>
                                    <th>Application ID's</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueIDs.map((uniqueID,index) => (
                                    <tr className='container_Empl_details_table_tr'>
                                        <td>{index+1}.</td>
                                        <td>{uniqueID.Unique_Code}</td>
                                        {/* <td><button className='container_Empl_details_button' onClick={() => toDeleteUser(uniqueID.id,uniqueID.full_name)}>Delete</button></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            }
            {verifiedIDs && 
                <div className='container_Empl_details' >
                    {Array.isArray(uniqueVerifiedIDs) && (
                        <table>
                            <thead>
                                <tr className='container_Empl_details_table_tr'>
                                    <th>No.</th>
                                    <th>Application ID's</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueVerifiedIDs.map((uniqueVerifiedID,index) => (
                                    <tr className='container_Empl_details_table_tr'>
                                        <td>{index+1}.</td>
                                        <td>{uniqueVerifiedID.Unique_Code}</td>
                                        {/* <td><button className='container_Empl_details_button' onClick={() => toDeleteUser(uniqueVerifiedID.id,uniqueVerifiedID.full_name)}>Delete</button></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            }
        </div>
        
    )
}

export default EmployeeReg;

