
import './App.css';
import Login from './components/login';
import Reg from './components/Reg';
import Signup from './components/Signup';
import ForgotPass from './components/ForgotPass';
import UserReg from './components/userReg';
import EmployeeReg from './components/EmployeeReg';
import { WebSocketProvider } from './components/WebSocketProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <WebSocketProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ForgotPass" element={<ForgotPass />} />
          <Route path="/UserReg" element={<UserReg />} />
          <Route path="/EmployeeReg" element={<EmployeeReg />} />
          <Route path="/Reg" element={<Reg />} />
          <Route path="/Signup" element={<Signup />} />
        </Routes>
      </Router>
      
      </WebSocketProvider>
    </div>
  );
}

export default App;
