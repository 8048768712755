import React, { useState,useRef,useEffect,useContext,useCallback  } from 'react'
import SignupLogo from './images/signup/signup.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./css/Signup.css";
import ShowHide from './images/signup/show_hide.png'
import "@fontsource/nunito"; // Defaults to weight 400
import "@fontsource/nunito/400.css"; // Specify weight
import "@fontsource/nunito/400-italic.css";
import { Password } from '@mui/icons-material';

import { useWebSocketContext } from './WebSocketProvider';

function Signup() {
    const apiBaseUrl = process.env.REACT_APP_API_URL

    const admin_details = {
        full_name: "",
        email: "",
        password: ""
    }
    const [adminInfo, setAdminInfo] = useState(admin_details)

    const handleSubmit = async () => {
        //console.log(adminInfo);
        // Validation check to ensure no required fields are null or empty
        if (!adminInfo.full_name || !adminInfo.email || !adminInfo.password) {
            alert("Please fill in all the required fields.");
            return;
        } else {
            if(userType === "employee") {
                alert("hi employee")
            } else if (userType === 'user') {
                try {
                
                    // Make a POST request to your server with the form data
                    const response = await axios.post(
                        `${apiBaseUrl}/admininfo`,
                        adminInfo
                    );
                    setAdminInfo(admin_details)
                    // Check the response status and handle accordingly
                    if (response.status === 200) {
    
                        alert(`Created profile for : ${adminInfo.full_name}`)
    
    
                    } else {
                        // Handle other response statuses or errors
                        console.error("Error submitting data:", response.data);
                    }
    
                    } catch (error) {
                        // Handle network errors or other exceptions
                        console.error("Error:", error.message);
                    }
            } else {
                alert("Please select usertype!")
            }
        }
    };

    const handleKeyDown = (e,fieldName) => {
        if (e.key === 'Enter') {
            focusNextInputField(fieldName)
        }
    }

    const focusNextInputField = (currentField) => {
        switch (currentField) {
            
            case 'fullname':
                mailRef.current.focus();
                break;
            case 'email':
                passRef.current.focus();
                break;
            case 'pass':
                submitRef.current.focus();
                break;
        }
    };

    const mailRef = useRef(null)
    const passRef = useRef(null)
    const submitRef = useRef(null)

    const { sendMessage, employeeState } = useWebSocketContext();
    const [userType, setUserType] = useState('employee');

    const handleRadioChange = useCallback(
        (type) => {
            setUserType(type);
            if (type === 'employee' && !employeeState) {
                // If userType is employee and employeeState is false (disabled), enable it
                const message = JSON.stringify({ type: 'EMPLOYEE_STATE_CHANGE', state: true });
                sendMessage(message);
            }
        },
        [employeeState, sendMessage]
    );
    // const { lastMessage } = useWebSocketContext();
    // const [employeeState, setEmployeeState] = useState(false);
    // useEffect(() => {
    //     if (lastMessage !== null) {
    //       try {
    //         const messageData = JSON.parse(lastMessage.data);
    //         if (messageData.type === 'EMPLOYEE_STATE_CHANGE') {
                
    //           setEmployeeState(messageData.state);
    //         }
    //       } catch (error) {
    //         console.error('Error parsing WebSocket message:', error);
    //       }
    //     }
    //   }, [lastMessage]);

    // const [employeeState,setEmployeeState] = useState({
    //     state:''
    // })

    // useEffect(() => {
    //     const checkValue = async () => {
    //         try {
    //             const response = await axios.get(`${apiBaseUrl}/getEmployeeState`);
                
    //             if (response.status === 200 && response.data) {
    //                 employeeState.state = response.data.state;
    //                 //console.log(employeeState.state);
    //             } else {
    //                 console.error('Failed to fetch employee state');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching employee state:', error);
    //         }
    //     };

    //     // Set interval to run the checkValue function every few seconds
    //     const intervalId = setInterval(checkValue, 1000); // Adjust the interval time as needed (2000 ms = 2 seconds)

    //     // Cleanup function to clear the interval when the component is unmounted
    //     // return () => clearInterval(intervalId);
    // }, []);

    return (
        <div className='Container_Signup'>
            <div className='Container_Signup_Logo'>
                <img src={SignupLogo} alt="?" />
            </div>
            <div className='Container_Signup_Info'>
                <div className='Signup_'>
                    <h4>Create Account</h4>
                    <div className='Signup_usertype'>
                        <span className='Signup_usertype_span' style={{textDecoration: 'underline'}}>User type</span>
                        <div className='usertype_radio'>
                            {/* <input type="radio" onClick={() => handleRadioChange('employee')} checked={userType === 'employee'} disabled={employeeState.state === 'false'}/>
                            <span>employee</span> */}
                            <input
                                type="radio"
                                onClick={() => handleRadioChange('employee')}
                                checked={userType === 'employee'}
                                disabled={!employeeState}
                            />
                            <span>employee</span>
                            <input type="radio" 
                            onClick={() => handleRadioChange('user')} checked={userType === 'user'} 
                            />
                            <span>user</span>
                        </div>
                        
                    </div>
                    <input type="text" placeholder="Full Name"
                        onKeyDown={(e) => handleKeyDown(e,'fullname')}
                        value={adminInfo.full_name}
                        onChange={(e) => setAdminInfo({ ...adminInfo, full_name: e.target.value })}
                    />
                    <input type="email" placeholder="Email Address"
                        ref={mailRef}
                        onKeyDown={(e) => handleKeyDown(e,'email')}
                        value={adminInfo.email}
                        onChange={(e) => setAdminInfo({ ...adminInfo, email: e.target.value })}
                    />
                    <div className='Signup_Pass'>
                        <input type="password" placeholder="Password"
                        ref={passRef}
                        onKeyDown={(e) => handleKeyDown(e,'pass')}
                        value={adminInfo.password}
                        onChange={(e) => setAdminInfo({ ...adminInfo, password: e.target.value })}
                        />
                    </div>
                    <button onClick={handleSubmit} ref={submitRef}>Create Account</button>
                    <span>Already have an account? <Link to="/" style={{ textDecoration: 'none', color: "#1B8C4E" }}>Log in</Link></span>
                </div>
            </div>
        </div >
    )
}

export default Signup
