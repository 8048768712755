import React, { useState,useRef,useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import RegLogo from './images/reg/reglogo.png';
import Owner from './images/reg/owner.png';
import Down from './images/reg/down.png';
import './css/UserReg.css'
import CustomOption from './CustomOption.jsx';
import CryptoJS from 'crypto-js';
import Select from 'react-select';
import Add from './images/reg/add.png';
import Remove from './images/reg/remove.png';
import Vector from './images/reg/Vector.png';
import Search from './images/reg/search.png';
import Cross_nav from './images/reg/cross_nav.png';
import Cross_ID from './images/reg/search_cross.png';

import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/Reg_Employee_Details.css";
import "./css/Reg.css";
import "./js/reg.js";
// function UserReg() {

// // const navigate = useNavigate();
// // const handleLogOut = () => {
// //      navigate(`/`);
// //   }
//   return (
//     <div className='Container_admin'>
//         <div className='Container_admin_adminLogo'> 
//             <div className='admin_adminLogo_nav'>
//                 <span></span>
//                 <span></span>
//                 <span></span>
//             </div>
//         </div>
//     </div>
//   )
// }
const customStyles = {
  option: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: state.isSelected ? '#1B8C4E' : '#FFF', 
      backgroundColor: state.isFocused ? '#1B8C4E' : '#FFF',
      color: state.isFocused ? '#FFF' : '#000',
      ':active': {
          ...provided[':active'],
          backgroundColor: '#1B8C4E' // Change background color when option is active (clicked)
        }
  }),
  input: (provided) => ({
      ...provided,
      width: '100% !important', // Ensure the input field stays at 100%
      '&:hover': {
          cursor: 'text'
      }
    }),
  valueContainer: (provided) => ({
      ...provided,
      overflow: 'hidden', // Prevent input field from expanding
    }),
  control: (provided, state) => ({
      ...provided,
      width: '100% !important',
      fontSize: '1em',
      border: '1px solid #000',
      borderRadius: '0.313rem',
      textAlign: 'left',
      // This line disable the blue border
      boxShadow: 'none',
      '&:hover': {
          border: '1px solid #000', // Change the background color on hover
          
      },
      '@media screen and (max-width: 1300px)': {
          fontSize: '0.9em',
      },
      '@media screen and (max-width: 700px)': {
          fontSize: '0.8em',
      },
      '@media screen and (max-width: 400px)': {
          fontSize: '0.7em',
      },

  }),
  placeholder: (provided, state) => ({
      ...provided,
      fontSize: '1rem',
      '@media screen and (max-width: 1300px)': {
          fontSize: '0.9em',
      },
      '@media screen and (max-width: 700px)': {
          fontSize: '1.1em',
      },
      '@media screen and (max-width: 400px)': {
          fontSize: '1em',
      },
  }),
  menu: (provided, state) => ({
      ...provided,
      fontSize: '.9rem',
      textAlign: 'left'
  }),
};

function UserReg() {
  const apiBaseUrl = process.env.REACT_APP_API_URL
  const location = useLocation();

    // Assume you have the secret key
    const secretKey = "5059";

    // Get the encrypted username from the URL parameter
    const urlParams = new URLSearchParams(location.search);
    const encryptedUsername = urlParams.get('username');

    let decryptedUsername = '';

    if (encryptedUsername) {
        // Decode the encrypted string
        const decodedEncryptedUsername = decodeURIComponent(encryptedUsername);

        // Decrypt the username
        const decryptedBytes = CryptoJS.AES.decrypt(decodedEncryptedUsername, secretKey);
        decryptedUsername = decryptedBytes.toString(CryptoJS.enc.Utf8);
    }

  const initialFormData = {
      id: "",
      from: "",
      to: "",
      purpose: "",
      priority: "",
      bankName: "",
      districtBank: "",
      districtProperty: "",
      branchName: "",
      branchCode: "",
      emNum: "",
      mortName: "",
      borrowName: "",
      accNum1: "",
      accNum2: "",
      accNum3: "",
      name: "",
      houseNo: "",
      postOff: "",
      pinCode: "",
      email: "",
      phoneNum: "",
      mobNum: "",
      documentNum: "",
      year: "",
      bookNum: "",
      hect: "",
      area: "",
      sqrMtre: "",
      acre: "",
      cent: "",
      inch: "",
      sro: "",
      taluk: "",
      village: "",
      desam: "",
      block: "",
      RsNo: "",
      RSSubDivNo: "",
      OldSNo: "",
      OldSubDivNo: "",
      unit: "",
      east: "",
      west: "",
      north: "",
      south: "",
      liabilities: "",
      remark: "",
      declaration: ""
  };
  const [formData, setFormData] = useState(initialFormData);
  const [searchField, setSearchField] = useState({
      search: ""
  })
// .........................................selectoption............................................

// 1
const initialOptions = {
  purpose: [],
  bankName: [],
  district: [],
  village: [],
  desam: [],
  taluk: []
  
};

const [options, setOptions] = useState(initialOptions);
const [inputValues, setInputValues] = useState({
  purpose: '',
  bankName: '',
  districtBank: '',
  districtProperty: '',
  village: '',
  desam: '',
  taluk: ''
  // Add other fields here
});

const selected_option_values = {
  purpose: '',
  bankName: '',
  districtBank: '',
  districtProperty: '',
  village: '',
  desam: '',
  taluk: ''
  // Add other fields here
}
const [selectedValues, setSelectedValues] = useState(selected_option_values);

const fromInputRef = useRef(null);
const toInputRef = useRef(null);
const purposeInputRef = useRef(null);
const priorityInputRef = useRef(null);
const bankNameInputRef = useRef(null);
const districtInputRef = useRef(null);
const districtInputRef2 = useRef(null);
const branchNameInputRef = useRef(null);
const branchCodeInputRef = useRef(null);
const emNumInputRef = useRef(null);
const mortNameInputRef = useRef(null);
const borrowNameInputRef = useRef(null);
const accNum1InputRef = useRef(null);
const  accNum2InputRef = useRef(null);
const accNum3InputRef = useRef(null);
const nameInputRef = useRef(null);
const  houseNoInputRef = useRef(null);
const postOffInputRef = useRef(null);
const pinCodeInputRef = useRef(null);
const emailInputRef = useRef(null);
const phoneNumInputRef = useRef(null);
const mobNumInputRef = useRef(null);
const documentNumInputRef = useRef(null);
const yearInputRef = useRef(null);
const bookNumInputRef = useRef(null);
const hectInputRef = useRef(null);
const areaInputRef = useRef(null);
const sqrMtreInputRef = useRef(null);
const sroInputRef = useRef(null);
const talukInputRef = useRef(null);
const villageInputRef = useRef(null);
const desamInputRef = useRef(null);
const blockInputRef = useRef(null);
const RsNoInputRef = useRef(null);
const RSSubDivNoInputRef = useRef(null);
const OldSNoInputRef = useRef(null);
const OldSubDivNoInputRef = useRef(null);
const unitOption1InputRef = useRef(null);
const unitOption2InputRef = useRef(null);
const eastInputRef = useRef(null);
const westInputRef = useRef(null);
const northInputRef = useRef(null);
const southInputRef = useRef(null);
const liabilitiesInputRef = useRef(null);
const remarkInputRef = useRef(null);
const declarationInputRef = useRef(null);
const yearsInputRef = useRef(null)
const submit_Ref = useRef(null)

const postOptions = async (name,newOption) => {
  if (name != 'districtBank' & name != 'districtProperty') {
      try {
          await axios.post(`${apiBaseUrl}/select_options/options/${name}`, newOption);
      } catch (error) {
         console.error('Error saving option:', error);
      }
  }
}

const handleKeyDown = (event, name, nextInputFocus) => {
  if (!inputValues[name]) {
      if(event.key == 'Enter'){
          focusNextInputField(nextInputFocus);
      }
      
  } else if (!selectedValues[name]) {
      if (name != 'districtBank' & name != 'districtProperty'){
          if ( event.key ===  'Tab' ) {
          
              const newOption = { label: inputValues[name], value: inputValues[name] }
              postOptions(name,newOption)
            //   fetchOptions()
              const getValue = inputValues[name]
              setSelectedValues((prevValues) => ({
                  ...prevValues,
                  [name]: newOption
              }));
              formData[name] = getValue
              
          } else if (event.key ===  'Enter' ) {
              const newOption = { label: inputValues[name], value: inputValues[name] }
              postOptions(name,newOption)
            //   fetchOptions()
              const getValue = inputValues[name]
              setSelectedValues((prevValues) => ({
                  ...prevValues,
                  [name]: newOption
              }));
              formData[name] = getValue
              
              focusNextInputField(nextInputFocus);
          }
      } else {
          if(event.key ===  'Enter'){
              focusNextInputField(nextInputFocus);
          }
      }
    //   fetchOptions()
  } 
//   fetchOptions()
  
};

const focusNextInputField = (nextInputFocus) => {
  if (nextInputFocus == accNum2InputRef) {
      if (addAccNum) {
          nextInputFocus.current.focus();
      } else {
          nameInputRef.current.focus();
      }
  } else if (nextInputFocus == accNum3InputRef) {
      if (addAccNum2) {
          nextInputFocus.current.focus();
      } else {
          nameInputRef.current.focus();
      }
  } else {
      nextInputFocus.current.focus();
  } 
} 

const focusNextRadio = (nextInputFocus) => {
  if (nextInputFocus.current) {
      nextInputFocus.current.focus();
  }
};

const handleRadioClick = (unit, selectedFieldRef) => {
  handleRadioChange(unit);
  focusNextRadio(selectedFieldRef);
};
// ....................................................................................................need
const fetchOptions = async () => {
  try {
      const fieldNames = Object.keys(initialOptions);
      const fetchPromises = fieldNames.map(fieldName => axios.get(`${apiBaseUrl}/select_options/options/${fieldName}`));
      const responses = await Promise.all(fetchPromises);
      const newOptions = {};
      responses.forEach((response, index) => {
          newOptions[fieldNames[index]] = response.data;
      });
      setOptions(newOptions);
      //console.log(newOptions);
  } catch (error) {
      console.error('Error fetching options:', error);
  }
};
useEffect(() => {
  // Fetch options for each field from the database on component mount
  fetchOptions();
}, []);
//...............

const handleChange = (selectedOption, name, nextField) => {
  setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: selectedOption,
  }));
  setFormData((prevValues) => ({
      ...prevValues,
      [name]: selectedOption.label ? selectedOption.label : selectedOption,
  }));
  focusNextInputField(nextField)
};

const handleInputChange = (newValue, name) => {
  setInputValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
  }));
  
};

const handleFocus = (fieldName) => {

  setInputValues(prevInputValues => ({
      ...prevInputValues,
      [fieldName]: selectedValues[fieldName]?.label || inputValues[fieldName]
  }));
  setSelectedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: "",
  }));
};

const handleBlur = (name) => {
    if (name !== 'districtBank' && name !== 'districtProperty') {
        const newOption = { label: inputValues[name], value: inputValues[name] }
    postOptions(name,newOption)
    const getValue = inputValues[name]
    // setSelectedValues((prevValues) => ({
    //     ...prevValues,
    //     [name]: newOption || selectedValues[name]?.label
    // }));
    selectedValues[name] = newOption
    formData[name] = getValue
    }
    
}

// ............................................................................................


  const [error, setError] = useState(false);

  const [addAccNum, setAddAccNum] = useState('')
  const handleOnClickAdd = () => {
      setAddAccNum(prevState => !prevState);
  };
  const [addAccNum2, setAddAccNum2] = useState('')
  const handleOnClickAdd2 = () => {
      setAddAccNum2(prevState => !prevState);
  };

  const handleField = (event, fieldName) => {
      let value;
      // Check if the event contains a label (for dropdown fields) or a value (for input fields)
      if (event.label) {
          value = event.label;
      } else {
          value = event.target.value;
      }

      switch (fieldName) {
          case 'year':
              if (value < 1900 || value > new Date().getFullYear()) {
                  setError(true);
              } else {
                  setError(false);
              }
              break;
          default:
              break;
      }

      // Update the form data
      setFormData(prevData => ({
          ...prevData,
          [fieldName]: value
      }));
  };

  const [regForm, setRegForm] = useState(true)
  const handleAddBtn = () => {
      setSearchDelete(false)
      setSearchModify(false)
      setFormData(initialFormData); // Reset formData to its initial values
      setRegForm(true)
      setSearchField('')
      setInputValues('')
  }

  const [searchModify, setSearchModify] = useState(false)
  const info_modify = "modify"
  const handleModify = async () => {
      const searchTerm = searchField.search;
      if (typeof searchTerm !== 'string' || searchTerm.trim() === "") { // Check if searchField.search is empty or contains only spaces
          alert("Please enter your Application_ID");
      } else {
          try {
              const response = await axios.get(
                  `${apiBaseUrl}/form_info/${searchField.search}`
              );
              if (response.status === 200) {
                  const foundData = response.data[0];
                  setSearchModify(true)
                  const mod_data = {
                          id: foundData.id,
                          unit: foundData.Unit,
                          accNum1: foundData.Account_Number1,
                          accNum2: foundData.Account_Number2,
                          accNum3: foundData.Account_Number3,
                          area: foundData.Area,
                          block: foundData.Block,
                          bankName: foundData.Bank_Name,
                          bookNum: foundData.Book_Number,
                          borrowName: foundData.Borrower_Name,
                          branchCode: foundData.Branch_Code,
                          branchName: foundData.Branch_Name,
                          declaration: foundData.Declaration,
                          desam: foundData.Desam,
                          districtBank: foundData.DistrictBank||"",
                          districtProperty: foundData.DistrictProperty||"",
                          documentNum: foundData.Document_Number,
                          emNum: foundData.EM_Number,
                          east: foundData.East,
                          email: foundData.Email_Id,
                          hect: foundData.Hector,
                          houseNo: foundData.House_No,
                          mortName: foundData.Mortagaor_Name.trim(),
                          mobNum: foundData.Mobile_Number,
                          name: foundData.Name.trim(),
                          north: foundData.North.trim(),
                          OldSNo: foundData.OLD_S_No,
                          OldSubDivNo: foundData.OLS_SUB_Div_No,
                          phoneNum: foundData.Phone_Number,
                          pinCode: foundData.Pincode,
                          postOff: foundData.Post_Office.trim(),
                          RsNo: foundData.RS_No,
                          RSSubDivNo: foundData.RS_Sub_Div_No,
                          remark: foundData.Remark.trim(),
                          sro: foundData.SRO,
                          south: foundData.South,
                          sqrMtre: foundData.Sq_mtr,
                          taluk: foundData.Taluk.trim(),
                          village: foundData.Village.trim(),
                          west: foundData.West.trim(),
                          from: foundData.from.split("T")[0],
                          priority: foundData.prtry.trim(),
                          purpose: foundData.purpose.trim(),
                          to: foundData.to.split("T")[0],
                          year: foundData.year,
                          acre: foundData.acre,
                          cent: foundData.cent,
                          inch: foundData.inch,
                          liabilities: foundData.liabilities
                  }
                  const transformedItemMod = transformItem(mod_data);
                  setFormData(transformedItemMod)
                  //console.log(selectedValues);
                  setRegForm(true)
                  setInputValues((prevValues) => ({
                      ...prevValues,
                      'bankName': foundData.Bank_Name,
                      'purpose' : foundData.purpose,
                      'districtBank' : foundData.District_Bank,
                      'districtProperty' : foundData.District_Property,
                      'village' : foundData.Village,
                      'desam' : foundData.Desam,
                      'taluk' : foundData.Taluk
                  }));
                  setSelectedValues((prevValues) => ({
                      ...prevValues,
                      'districtBank' : foundData.District_Bank,
                      'districtProperty' : foundData.District_Property,
                  }));
                  
                  //console.log(foundData.District_Bank);
                  
              }
          } catch (error) {
              if (error.response && error.response.status === 404) {
                  alert("No data found for the provided ID");
                  //console.log(error.response.data.message);
              } else {
                  console.error("Error:", error.message);
              }
  
          }
      }
      
  };

  const [searchDelete, setSearchDelete] = useState(false)

  useEffect(() => {
      const customDeleteBtn = () => {
          if (typeof searchField.search === 'string' && searchField.search.trim().length > 0) {
              setSearchDelete(true);
          } else {
              setSearchDelete(false);
          }
      };
      customDeleteBtn();
  }, [searchField]);
  

  const handleDelete = async () => {
      const searchTerm = searchField.search;
      if (typeof searchTerm !== 'string' || searchTerm.trim() === "") { // Check if searchField.search is empty or contains only spaces
          alert("Please enter your Application_ID");
      } else {
          const userConfirmed = window.confirm("Are you sure you want to delete this entry?");
          if (userConfirmed) {
              try {
                  if (searchField.search !== '') {
                      const response = await axios.delete(
                          `${apiBaseUrl}/delete_info/${searchField.search}`
                      );
                      //console.log(response);
                      if (response.status === 200) {
                          setSearchField('')
                          setSearchModify(false)
                          setRegForm(true)
                          setFormData(initialFormData)
                          setInputValues('')
                          alert(response.data.message);
                      } else {
                          alert(response.data.message);
                      }
                  }
              } catch (error) {
                  console.error("Error:", error.message);
              }
          } else {
              alert("No data found for the provided ID");
          }
      }
  };

  const [errorFrom,setErrorFrom] = useState(false)
  const [errorTo,setErrorTo] = useState(false)

  const handleSubmit = async () => {
  if (error || formData.from == '' || formData.to == '' || formData.bankName == '' || formData.districtBank == '' || formData.branchCode == '') {
      alert('Please fill the required entry!')
  } else {
      const userConfirmed = window.confirm("Do you want to Submit this entry?");
      if (userConfirmed) {
          try {
              // Make a POST request to your server with the form data
              const response = await axios.post(
                  `${apiBaseUrl}/appinfo`,
                  formData
              );
              //console.log(formData);

              // Check the response status and handle accordingly
              if (response.status === 200) {
                  setSearchModify(false)
                  alert("Data submitted")
                  setInputValues((prevValues) => ({
                      ...prevValues,
                      'bankName': '',
                      'purpose' : '',
                      'districtBank' : null,
                      'districtProperty' : null,
                      'village' : '',
                      'desam' : '',
                      'taluk' : ''
                  }));
                  setFormData(initialFormData)
                  setSelectedValues(selected_option_values)
                  setSearchField('')
                  
                  // Scroll to the header element
                  const header = document.getElementById('header');
                  if (header) {
                      header.scrollIntoView({ behavior: 'smooth' });
                  }

              } else {
                  // Handle other response statuses or errors
                  console.error("Error submitting data:", response.data);
              }

      } catch (error) {
          // Handle network errors or other exceptions
          console.error("Error:", error.message);
      }
      }
  }
      
  };

  const [searched_ID, setSearched_ID] = useState(false)
  // const handleSearchByAccountNo = async () => {
  //     const searchTerm = searchField.search;
  //     if (typeof searchTerm !== 'string' || searchTerm.trim() === "") { // Check if searchField.search is empty or contains only spaces
  //         alert("Please enter your Application_ID");
  //     } else{
  //         try {
          
  //             const response = await axios.get(
  //                 `${apiBaseUrl}/form_info/${searchField.search}`
  //             );
              
  //             if (response.status === 200) {
  //                 setRegForm(false)
  //                 setSearchField('')
  //                 const foundData = response.data[0];
                  
  //                     try {
  //                         const response = await axios.post(`${apiBaseUrl}/update-json`, foundData);
  //                         if (response.status === 200) {
  //                         } else {
  //                             console.error("Error finding data:", response.data);
  //                         }
  //                     } catch (error) {
  //                         if (error.response && error.response.status === 400) {
  //                             // If data already exists, show an alert
  //                             alert(error.response.data.error);
  //                         } else {
  //                             console.error("Error updating JSON data:", error);
  //                         }
  //                     }
  
  //             } else {
  //                 console.error("Error finding data:", response.data);
  //             }
  
  //         } catch (error) {
  //             // Handle network or other errors
  //             if (error.response && error.response.status === 404) {
  //                 // No data found
  //                 alert("No data found");
  //             } else {
  //                 console.error("Error:", error.message);
  //             }
  //         }
  //     }
      
  // };

  const [userProfile, setUserProfile] = useState(false);
  const handleUserProfile = () => {
      setUserProfile(prevState => !prevState);
  };

  const navigate = useNavigate();
  const handleLogOut = () => {
      navigate(`/`);
  }

  const [certificate, setCertificate] = useState(false)
  const [certItem, setCertItem] = useState(null);

  const transformItem = (item) => {
      // Create a new object to avoid mutating the original item
      const newItem = { ...item };
  
      // Iterate over the properties of the item
      for (const key in newItem) {
        if (newItem.hasOwnProperty(key)) {
          // Check if the value is 0 and set it to null if true
          if (newItem[key] === 0) {
            newItem[key] = null;
          }
        }
      }
  
      return newItem;
    };

  const [selectedUnit, setSelectedUnit] = useState('');

  const handleRadioChange = (unit) => {
      setSelectedUnit(unit);
  };

  const handleKeyDownRadio = (e,nextradio,selectedfield,unit) => {
      if(e.key === 'Enter' || e.key === 'Return'){
          if (selectedUnit) {
              focusNextInputField(selectedfield)
          } else {
              focusNextRadio(nextradio)
          }
          
      } else if (e.key === ' ') {
          e.preventDefault(); // Prevent space from scrolling the page
          handleRadioChange(unit)
          
      }
  }

  const [home,setHome] = useState(true)

  const handleDeleteOption = async (option,fieldName) => {
      const toDeleteOption = option.label
      try {
          const userConfirmed = window.confirm("Do you want to delete " +toDeleteOption+ "?");
          if (userConfirmed) {
              // Make an HTTP request to delete the specific option
              const response = await axios.delete(`${apiBaseUrl}/delete-specific-option/${fieldName}/${toDeleteOption}`);
              if (response.status == 200) {
                  alert(`Successfully Deleted : ${toDeleteOption}`)
                  fetchOptions()
              }
          }
          
      } catch (error) {
          console.error('Error deleting specific option:', error);
      }
  };

  return (
      <div className='Container_Reg'>
          {(home) &&
              <div id='header'>
                  <div className='Container_Reg_RegLogo_img'> 
                      <div className='Account_owner'>
                          <img className="Owner_Img" src={Owner} alt=""/>
                          <span onClick={handleUserProfile}>{decryptedUsername}</span>
                          <img className="Down_Img" src={Down} alt="" onClick={handleUserProfile}/>
                      </div>
                      <img className="RegLogo_img" src={RegLogo} alt=""/>
                      {userProfile &&
                          <div className={`user_Logout ${userProfile ? 'user_Logout_show' : 'user_Logout_hide'}`}>
                              <button onClick={handleLogOut}>Log out</button>
                          </div>
                      }
                  </div>
                  <div className='Container_Reg_Search_Modify_Application'>
                      <div className='Container_Reg_Search'>
                          <div className='Search_Div'>
                              <img src={Search} alt="?" />
                              <input type="search" value={searchField.search} placeholder="Application ID..."  onChange={(e) =>
                                  setSearchField({ ...searchField, search: e.target.value })
                              } />
                          </div>
                          {/* <button className="Container_Reg_Search_Btn" onClick={handleSearchByAccountNo}>Search</button> */}
                      </div>
                      <div className='Container_Reg_Update'>
                          <button className={`Container_Reg_Update_Btn ${(regForm & !searchModify) ? 'Update_Btn' : ''}`} onClick={handleAddBtn}>Add</button>
                          <button className={`Container_Reg_Update_Btn ${(regForm & searchModify) ? 'Update_Btn' : ''}`} onClick={handleModify}>Modify</button>
                          <button className={`Container_Reg_Update_Btn ${searchDelete ? 'Update_Btn_delete' : ''}`} onClick={handleDelete}>Delete</button>
                          <button className="Container_Reg_Update_Btn" onClick={handleSubmit} ref={submit_Ref}>Submit</button>
                      </div>
                  </div>

                  {/* ADD BUTTON */}

                  {(regForm) &&
                        < div className='Container_Reg_Info'>
                            <div className='All_Reg_Section'>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Bank Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Bank Name</span></div>
                                                <div className='TextInput_Input'>
                                                    <Select
                                                    ref={bankNameInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.bankName}
                                                    options={options.bankName}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'bankName',districtInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'bankName')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'bankName',districtInputRef)}
                                                    inputValue={inputValues.bankName}
                                                    onFocus={() => handleFocus('bankName')}
                                                    onBlur={() => handleBlur('bankName')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'bankName')} /> }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>District</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={districtInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.districtBank}
                                                    options={options.district}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'districtBank',branchNameInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'districtBank')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'districtBank',branchNameInputRef)}
                                                    inputValue={inputValues.districtBank}
                                                    onFocus={() => handleFocus('districtBank')}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Branch Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.branchName} onChange={(e) => handleField(e, 'branchName')} 
                                                    ref={branchNameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'branchName',branchCodeInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Branch Code</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.branchCode} onChange={(e) => handleField(e, 'branchCode')} 
                                                    ref={branchCodeInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'branchCode',emNumInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>EM Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.emNum} onChange={(e) => handleField(e, 'emNum')} 
                                                    ref={emNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'emNum',mortNameInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Mortagaor Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.mortName} onChange={(e) => handleField(e, 'mortName')} 
                                                    ref={mortNameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'mortName',borrowNameInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Borrower Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.borrowName} onChange={(e) => handleField(e, 'borrowName')} 
                                                    ref={borrowNameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'borrowName',accNum1InputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Account Number</span></div>
                                                <div className='TextInput_Input TextInput_Input_Acc'>
                                                    {!addAccNum && <img src={Add} alt="?" className='Add_Img' onClick={handleOnClickAdd} />}
                                                    <input min="0" type="number" className={!addAccNum ? 'AccNum_AddImg' : ''} value={formData.accNum1} onChange={(e) => handleField(e, 'accNum1')} 
                                                    ref={accNum1InputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'accNum1',accNum2InputRef)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={addAccNum ? 'Div_Tail_Row' : 'Div_Tail_Row_None'}>
                                            <div className='TextInput'>
                                                {addAccNum &&
                                                    <div className='TextInput_Text'><span>Account Number 2</span></div>
                                                }
                                                {addAccNum &&
                                                    <div className='TextInput_Input TextInput_Input_Acc'>
                                                        {!addAccNum2 && <img src={Add} alt="?" className='Add_Img' onClick={handleOnClickAdd2} />}
                                                        <input min="0" type="number" className={!addAccNum2 ? 'AccNum_AddImg' : ''} value={formData.accNum2} onChange={(e) => handleField(e, 'accNum2')} 
                                                    ref={accNum2InputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'accNum2',accNum3InputRef)}/>
                                                    </div>
                                                }
                                            </div>
                                            <div className='TextInput'>
                                                {addAccNum2 &&
                                                    <div className='TextInput_Text'><span>Account Number 3</span></div>
                                                }
                                                {addAccNum2 &&
                                                    <div className='TextInput_Input TextInput_Input_Acc'>
                                                        <img src={Remove} alt="?" className='Add_Img' onClick={handleOnClickAdd2} />
                                                        <input min="0" type="number" className="AccNum3_AddImg" value={formData.accNum3} onChange={(e) => handleField(e, 'accNum3')} 
                                                    ref={accNum3InputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'accNum3',nameInputRef)}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div >

                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Application Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Name</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.name} onChange={(e) => handleField(e, 'name')} 
                                                    ref={nameInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'name',houseNoInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>House Name/No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.houseNo} onChange={(e) => handleField(e, 'houseNo')} 
                                                    ref={houseNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'houseNo',postOffInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Post Office</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.postOff} onChange={(e) => handleField(e, 'postOff')} 
                                                    ref={postOffInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'postOff',pinCodeInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Pincode</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.pinCode} onChange={(e) => handleField(e, 'pinCode')} 
                                                    ref={pinCodeInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'pinCode',emailInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Email Id</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.email} onChange={(e) => handleField(e, 'email')} 
                                                    ref={emailInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'email',phoneNumInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Phone Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.phoneNum} onChange={(e) => handleField(e, 'phoneNum')} 
                                                    ref={phoneNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'phoneNum',mobNumInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Mobile Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.mobNum} onChange={(e) => handleField(e, 'mobNum')} 
                                                    ref={mobNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'mobNum',documentNumInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Document Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Document Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.documentNum} onChange={(e) => handleField(e, 'documentNum')} 
                                                    ref={documentNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'documentNum',yearInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Year</span></div>
                                                <div className='TextInput_Input'>
                                                    <input
                                                    ref={yearInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'year',bookNumInputRef)}
                                                        className={`TextInput_Input Input ${error ? 'error' : ''}`}
                                                        type="number"
                                                        min="1900"
                                                        max={new Date().getFullYear()}
                                                        value={formData.year}
                                                        onChange={(e) => handleField(e, 'year')}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Book Number</span></div>
                                                <div className='TextInput_Input'><input min="0" type="number" value={formData.bookNum} onChange={(e) => handleField(e, 'bookNum')} 
                                                    ref={bookNumInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'bookNum',sroInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Property Details</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>SRO</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.sro} onChange={(e) => handleField(e, 'sro')} 
                                                    ref={sroInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'sro',districtInputRef2)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>District</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={districtInputRef2}
                                                    styles={customStyles}
                                                    value={selectedValues.districtProperty}
                                                    options={options.district}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'districtProperty',talukInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'districtProperty')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'districtProperty',talukInputRef)}
                                                    inputValue={inputValues.districtProperty}
                                                    onFocus={() => handleFocus('districtProperty')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Taluk</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={talukInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.taluk}
                                                    options={options.taluk}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'taluk',villageInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'taluk')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'taluk',villageInputRef)}
                                                    inputValue={inputValues.taluk}
                                                    onFocus={() => handleFocus('taluk')}
                                                    onBlur={() => handleBlur('taluk')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'taluk')} /> }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Village</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={villageInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.village}
                                                    options={options.village}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'village',desamInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'village')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'village',desamInputRef)}
                                                    inputValue={inputValues.village}
                                                    onFocus={() => handleFocus('village')}
                                                    onBlur={() => handleBlur('village')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'village')} /> }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Desam</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={desamInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.desam}
                                                    options={options.desam}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'desam',blockInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'desam')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'desam',blockInputRef)}
                                                    inputValue={inputValues.desam}
                                                    onFocus={() => handleFocus('desam')}
                                                    onBlur={() => handleBlur('desam')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'desam')} /> }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Block</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.block} onChange={(e) => handleField(e, 'block')} 
                                                    ref={blockInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'block',RsNoInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>RS No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.RsNo} onChange={(e) => handleField(e, 'RsNo')} 
                                                    ref={RsNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'RsNo',RSSubDivNoInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>RS Sub Div No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.RSSubDivNo} onChange={(e) => handleField(e, 'RSSubDivNo')} 
                                                    ref={RSSubDivNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'RSSubDivNo',OldSNoInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>OLD S No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.OldSNo} onChange={(e) => handleField(e, 'OldSNo')} 
                                                    ref={OldSNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'OldSNo',OldSubDivNoInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>OLS SUB Div No</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.OldSubDivNo} onChange={(e) => handleField(e, 'OldSubDivNo')} 
                                                    ref={OldSubDivNoInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'OldSubDivNo',unitOption1InputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Unit</span></div>
                                                <div className='TextInput_Radio'>
                                                    <div className='TextInput_Input_Checkbox'>
                                                        <span>MKS</span>
                                                        <input type="radio"
                                                        onClick={() => handleRadioClick('MKS', hectInputRef)}
                                                        onKeyDown={(event) => handleKeyDownRadio(event,unitOption2InputRef,unitOption2InputRef,'MKS')}
                                                        ref={unitOption1InputRef} checked={selectedUnit === 'MKS'} />
                                                    </div>
                                                    <div className='TextInput_Input_Checkbox'>
                                                        <span>FPS</span>
                                                        <input type="radio" 
                                                        onClick={() => handleRadioClick('FPS', hectInputRef)}
                                                        onKeyDown={(event) => handleKeyDownRadio(event,eastInputRef,hectInputRef,'FPS')}
                                                        ref={unitOption2InputRef} checked={selectedUnit === 'FPS'} />
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedUnit === 'MKS' && 
                                                <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Hectre/Area/Sq.Mtr</span></div>
                                                <div className='TextInput_Input'>
                                                    <div className='TextInput_Input_Num'>
                                                        <input min="0" type="number" placeholder="Hectre" value={formData.hect} onChange={(e) => handleField(e, 'hect')} 
                                                    ref={hectInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'hect',areaInputRef)}/>
                                                        <input min="0" type="number" placeholder="Are" value={formData.area} onChange={(e) => handleField(e, 'area')} 
                                                    ref={areaInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'area',sqrMtreInputRef)}/>
                                                        <input min="0" type="number" placeholder="Sq.mtre" value={formData.sqrMtre} onChange={(e) => handleField(e, 'sqrMtre')} 
                                                    ref={sqrMtreInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'sqrMtre',eastInputRef)}/>
                                                    </div>
                                                </div>
                                                </div>
                                            }
                                            {selectedUnit === 'FPS' && 
                                                <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Acre/Cent/Inch</span></div>
                                                <div className='TextInput_Input'>
                                                    <div className='TextInput_Input_Num'>
                                                        <input min="0" type="number" placeholder="Acre" value={formData.acre} onChange={(e) => handleField(e, 'acre')} 
                                                    ref={hectInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'hect',areaInputRef)}/>
                                                        <input min="0" type="number" placeholder="Cent" value={formData.cent} onChange={(e) => handleField(e, 'cent')} 
                                                    ref={areaInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'area',sqrMtreInputRef)}/>
                                                        <input min="0" type="number" placeholder="Inch" value={formData.inch} onChange={(e) => handleField(e, 'inch')} 
                                                    ref={sqrMtreInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'sqrMtre',eastInputRef)}/>
                                                    </div>
                                                </div>
                                                </div>
                                            }
                                            {selectedUnit === '' &&
                                                <div className='TextInput'></div>
                                            }
                                            
                                        </div>
                                    </div >
                                </div >
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Boundry of the Property</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>East</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.east} onChange={(e) => handleField(e, 'east')} 
                                                    ref={eastInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'east',westInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>West</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.west} onChange={(e) => handleField(e, 'west')} 
                                                    ref={westInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'west',northInputRef)}/></div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>North</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.north} onChange={(e) => handleField(e, 'north')} 
                                                    ref={northInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'north',southInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>South</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.south} onChange={(e) => handleField(e, 'south')} 
                                                    ref={southInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'south',fromInputRef)}/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Search Period</div>
                                    <div className='Div_Tail'>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>From</span></div>
                                                <div className='TextInput_Input'>
                                                    <input
                                                    className={`${errorFrom ? 'errorFromTo' : ''}`}
                                                    ref={fromInputRef}
                                                    type="date"
                                                    value={formData.from}
                                                    onKeyDown={(event) => handleKeyDown(event, 'from',toInputRef)}
                                                    onChange={(e) => handleField(e, 'from')} />
                                                </div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>To</span></div>
                                                <div className='TextInput_Input'><input
                                                    className={`${errorTo ? 'errorFromTo' : ''}`}
                                                    type="date"
                                                    ref={toInputRef}
                                                    value={formData.to}
                                                    onKeyDown={(event) => handleKeyDown(event, 'to',yearsInputRef)}
                                                    onChange={(e) => handleField(e, 'to')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Years</span></div>
                                                <div className='TextInput_Input'><input type="number" value={formData.years} onChange={(e) => handleField(e, 'years')} 
                                                        ref={yearsInputRef}
                                                        onKeyDown={(event) => handleKeyDown(event, 'years',purposeInputRef)}/></div>
                                            </div>
                                            <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Purpose</span></div>
                                                <div className='TextInput_Input'>
                                                <Select
                                                    ref={purposeInputRef}
                                                    styles={customStyles}
                                                    value={selectedValues.purpose}
                                                    options={options.purpose}
                                                    onChange={(selectedOption) => handleChange(selectedOption, 'purpose',priorityInputRef)}
                                                    onInputChange={(newValue) => handleInputChange(newValue, 'purpose')}
                                                    onKeyDown={(event) => handleKeyDown(event, 'purpose',priorityInputRef)}
                                                    inputValue={inputValues.purpose}
                                                    onFocus={() => handleFocus('purpose')}
                                                    onBlur={() => handleBlur('purpose')}
                                                    components={{ Option: (props) => <CustomOption {...props} 
                                                    handleDeleteOption={(option) => handleDeleteOption(option, 'purpose')} /> }}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Div_Tail_Row'>
                                        <div className='TextInput'>
                                                <div className='TextInput_Text'><span>Priority</span></div>
                                                <div className='TextInput_Input'><input type="text" value={formData.priority} onChange={(e) => handleField(e, 'priority')} 
                                                        ref={priorityInputRef}
                                                        onKeyDown={(event) => handleKeyDown(event, 'priority',liabilitiesInputRef)}/></div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Liabilities</div>
                                    <div className='Declation_Tail'>
                                        <textarea type="text" placeholder="Enter here..." rows={1} cols={20} value={formData.liabilities} onChange={(e) => handleField(e, 'liabilities')} 
                                                    ref={liabilitiesInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'remark',remarkInputRef)}/>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Remark</div>
                                    <div className='Declation_Tail'>
                                        <textarea type="text" placeholder="Enter here..." rows={1} cols={20} value={formData.remark} onChange={(e) => handleField(e, 'remark')} 
                                                    ref={remarkInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'remark',declarationInputRef)}/>
                                    </div>
                                </div>
                                <div className='Reg_Section'>
                                    <div className='Div_Head'>Declaration</div>
                                    <div className='Declation_Tail'>
                                        <textarea type="text" placeholder="Enter here..." rows={1} cols={20} value={formData.declaration} onChange={(e) => handleField(e, 'declaration')} 
                                                    ref={declarationInputRef}
                                                    onKeyDown={(event) => handleKeyDown(event, 'declaration',submit_Ref)}/>
                                    </div>
                                </div>
                            </div >
                            {/* <div className='SubmitReset'>
                        <button className='SubmitBtn' onClick={handleSubmit}>Submit Application</button>
                        <button className='ResetBtn'>Reset</button>
                    </div> */}
                        </div>
                    }
              </div>
          }

      </div>
      
  )
}

export default UserReg;