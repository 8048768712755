import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
// import './css/login-main.css';
import "./css/login.css";
import "@fontsource/nunito"; // Defaults to weight 400
import "@fontsource/nunito/400.css"; // Specify weight
import "@fontsource/nunito/400-italic.css";
import "@fontsource/trykker";
// import "./css/util-login.css";
// import "./css/animation.css";
// import "./css/animation.min.css";
// import "./vendor/bootstrap/css/bootstrap.min.css";
// import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
// import "./fonts/iconic/css/material-design-iconic-font.min.css";
// import "./vendor/animate/animate.css";
// import "./vendor/css-hamburgers/hamburgers.min.css";
// import "./vendor/animsition/css/animsition.min.css";
// import "./vendor/select2/select2.min.css";
// import "./vendor/daterangepicker/daterangepicker.css";
import JPLogo from "./images/login/JPLogo.png";
import JPLogoName from "./images/login/JPLogoName.png";
// import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";

function Login() {
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const [isChecked, setIsChecked] = useState(false);
    const [userData, setUserData] = useState({
        username: "",
        password: ""
    });

    const [userType, setUserType] = useState(''); // State for user type

    useEffect(() => {
        // Push a new state to the history stack when the component mounts
        window.history.pushState(null, null, window.location.pathname);
    
        // Add a listener to the 'popstate' event
        window.addEventListener('popstate', handlePopstate);
    
        // Clean up the listener when the component unmounts          
        return () => {
          window.removeEventListener('popstate', handlePopstate);
        };
      }, []);
    
      const handlePopstate = () => {
        // Push another state to the history stack, effectively preventing the user from going back
        window.history.pushState(null, null, window.location.pathname);
      };

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedPassword = localStorage.getItem('password');
        if (storedUsername && storedPassword) {
            setUserData({ username: storedUsername, password: storedPassword });
            setIsChecked(true);
        }
    }, []);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if(userData.username === "" || userData.password === ""){
            alert('Please enter username or password!')
        } else {
            if (userType === 'user') {
                try {
                    const response = await axios.get(
                        `${apiBaseUrl}/user_info/${userData.username}/${userData.password}`
                    );
        
                    if (response.status === 200) {
                        const UserCount = response.data.user_count;
                        const fullName = response.data.userData.full_name;
                        const firstName = fullName.split(' ')[0]; // Extracting the first word
        
                        if (UserCount === 1) {
                            if (isChecked) {
                                localStorage.setItem('username', userData.username);
                                localStorage.setItem('password', userData.password);
                            } else {
                                localStorage.removeItem('username');
                                localStorage.removeItem('password');
                            } 
                            // navigate(`/UserReg?username=${firstName}`);
                            const secretKey = "5059";

            // Encrypt the firstName
            const encryptedFirstName = CryptoJS.AES.encrypt(firstName, secretKey).toString();

            // Encode the encrypted string to make it URL safe
            const encodedEncryptedFirstName = encodeURIComponent(encryptedFirstName);

            // Redirect to registration page with encrypted username as URL parameter
            navigate(`/UserReg?username=${encodedEncryptedFirstName}`);
                        } else {
                            alert("username or password is wrong!")
                        }
        
                    } else {
                        console.error("Error finding data:", response.data);
                    }
                } catch (error) {
                    console.error("Error:", error.message);
                    alert('username or password is wrong!')
                }
            } else if (userType === 'admin') {
                try {
                    const response = await axios.get(
                        `${apiBaseUrl}/admin_info/${userData.username}/${userData.password}`
                    );
        
                    if (response.status === 200) {
                        //console.log(response.data);
                        const UserCount = response.data.user_count;
                        const fullName = response.data.userData.username;
                        const firstName = fullName.split(' ')[0]; // Extracting the first word
        
                        if (UserCount === 1) {
                            if (isChecked) {
                                localStorage.setItem('username', userData.username);
                                localStorage.setItem('password', userData.password);
                            } else {
                                localStorage.removeItem('username');
                                localStorage.removeItem('password');
                            } 
                            // navigate(`/Reg?username=${firstName}`);
                            // Define a secret key for encryption. Keep this key secure!
            const secretKey = "5059";

            // Encrypt the firstName
            const encryptedFirstName = CryptoJS.AES.encrypt(firstName, secretKey).toString();

            // Encode the encrypted string to make it URL safe
            const encodedEncryptedFirstName = encodeURIComponent(encryptedFirstName);

            // Redirect to registration page with encrypted username as URL parameter
            navigate(`/Reg?username=${encodedEncryptedFirstName}`);
                        } else {
                            alert("username or password is wrong!")
                        }
        
                    } else {
                        console.error("Error finding data:", response.data);
                    }
                } catch (error) {
                    console.error("Error:", error.message);
                    alert('username or password is wrong!')
                }
            } else if (userType === 'employee'){
                try {
                    const response = await axios.get(
                        `${apiBaseUrl}/employee_info/${userData.username}/${userData.password}`
                    );
        
                    if (response.status === 200) {
                        const UserCount = response.data.user_count;
                        const fullName = response.data.userData.username;
                        const firstName = fullName.split(' ')[0]; // Extracting the first word
                        //console.log(UserCount);
                        if (UserCount === 1) {
                            if (isChecked) {
                                localStorage.setItem('username', userData.username);
                                localStorage.setItem('password', userData.password);
                            } else {
                                localStorage.removeItem('username');
                                localStorage.removeItem('password');
                            } 
                            // navigate(`/EmployeeReg?username=${firstName}`); 
                            // Define a secret key for encryption. Keep this key secure!
            const secretKey = "5059";

            // Encrypt the firstName
            const encryptedFirstName = CryptoJS.AES.encrypt(firstName, secretKey).toString();

            // Encode the encrypted string to make it URL safe
            const encodedEncryptedFirstName = encodeURIComponent(encryptedFirstName);

            // Redirect to registration page with encrypted username as URL parameter
            navigate(`/EmployeeReg?username=${encodedEncryptedFirstName}`);
                        } else {
                            alert("username or password is wrong!")
                        }
        
                    } else {
                        console.error("Error finding data:", response.data);
                    }
                } catch (error) {
                    console.error("Error:", error.message);
                    alert('username or password is wrong!')
                }
            } else { 
                alert("Please select usertype!")
            }
            
        }
    };

    const UsernameRef = useRef(null);
    const PasswordRef = useRef(null);
    const LoginRef = useRef(null);

    const focusToNextField = (nextRef) => {
        nextRef.current.focus();
    }

    const handleKeyDown = (e, nextRef) => {
        if (e.key === 'Enter' || e.key === 'Return') {
            focusToNextField(nextRef);
        }
    }

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

    const handleRadioChange = (usertype) => {
        setUserType(usertype)
    }

    return (
        <div className="container_login100">
            <div className="container_login100_Logo">
                <img src={JPLogo} alt="?" className="container_login100_Logo_img" />
                <img src={JPLogoName} alt="?" className="container_login100_Logo_Name_img" />
            </div>
            <div className="container_login100_Login">
                <div className="container_login100_Login_LoginForm">
                    <span className="container_login100_Login_LoginForm_span">Login</span>
                    <div className="Login_LoginForm_Email">
                        <span className="Login_LoginForm_Email_span" style={{textDecoration:'underline'}}>User type</span>
                        <div className="Login_LoginForm_usertype">
                            <input className="Login_LoginForm_usertype_radio" type="radio" id="adminRadio" onClick={() => handleRadioChange('admin')} checked={userType === 'admin'}/>
                            <span htmlFor="adminRadio">admin</span>
                            <input className="Login_LoginForm_usertype_radio" type="radio" id="userRadio"  onClick={() => handleRadioChange('employee')} checked={userType === 'employee'}/>
                            <span htmlFor="userRadio">employee</span>
                            <input className="Login_LoginForm_usertype_radio" type="radio" id="userRadio"  onClick={() => handleRadioChange('user')} checked={userType === 'user'}/>
                            <span htmlFor="userRadio">user</span>
                        </div>
                    </div>
                    <div className="Login_LoginForm_Email">
                        <span>Username</span>
                        <input type="text" placeholder="Username"
                            ref={UsernameRef}
                            value={userData.username}
                            onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                            onKeyDown={(event) => handleKeyDown(event, PasswordRef)}
                        />
                    </div>
                    <div className="Login_LoginForm_Pass">
                        <span>Password</span>
                        <input type="password" placeholder="Password"
                            ref={PasswordRef}
                            value={userData.password}
                            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                            onKeyDown={(event) => handleKeyDown(event, LoginRef)}
                        />
                    </div>
                    <div className="Login_LoginForm_RemForgt">
                        <div className="Login_LoginForm_Rem">
                            <input type="checkbox" id="myCheckbox" checked={isChecked} onChange={handleCheckboxChange} />
                            <label htmlFor="myCheckbox" id="checkboxLabel">Remember Me</label>
                        </div>
                        <div className="Login_LoginForm_Forgt">
                            <span><Link className="Login_LoginForm_Forgt_Link" to="/forgotpass">Forgot Password?</Link></span>
                        </div>
                    </div>
                    
                    <button ref={LoginRef} onClick={handleSubmit}>Login</button>
                    <div className="Login_LoginForm_To_Signup">
                        <span>Don't have an account yet? <Link to="/signup" style={{ textDecoration: 'none', color: '#1B8C4E' }}>Sign up</Link></span>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Login;
