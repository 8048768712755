// WebSocketProvider.jsx

import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const WebSocketContext = createContext(null);

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [employeeState, setEmployeeState] = useState(false); // Initialize with false

    useEffect(() => {
        const ws = new WebSocket('wss://echo.websocket.org/');
        setSocket(ws);

        ws.onopen = () => {
            //console.log('WebSocket connection established');
            setIsConnected(true);
        };

        ws.onclose = () => {
            //console.log('WebSocket connection closed');
            setIsConnected(false);
        };

        ws.onerror = (error) => {
            console.error('WebSocket error', error);
        };

        ws.onmessage = (message) => {
            //console.log('Received message:', message.data);
            try {
                const messageData = JSON.parse(message.data);
                if (messageData.type === 'EMPLOYEE_STATE_CHANGE') {
                    setEmployeeState(messageData.state);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        return () => {
            ws.close();
        };
    }, []);

    const sendMessage = useCallback((data) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(data);
        } else {
            console.error('WebSocket connection not established or closed.');
        }
    }, [socket]);

    return (
        <WebSocketContext.Provider value={{ sendMessage, isConnected, employeeState }}>
            {children}
        </WebSocketContext.Provider>
    );
};
