import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./css/forgotPass.css";
import ForgotVector from "./images/forgotpass/forgotVector.png";
import ForgotVectorMob from "./images/forgotpass/forgotmobvec.png";
import Back from "./images/forgotpass/back.png";

function ForgotPass() {
const apiBaseUrl = process.env.REACT_APP_API_URL
const forget_pass = {
    email:""
}

const [forgetpass,setForgetpass] = useState(forget_pass)
const handleEmail = (e) => {
    setForgetpass({ ...forgetpass, email: e.target.value });
};

const [otp, setOtp] = useState('');
const generateOtp = () => {
    // Function to generate a random 6-digit OTP
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

const [message, setMessage] = useState('');
const handleSubmit = async (e) => {
    e.preventDefault();
    const generatedOtp = generateOtp();
    setOtp(generatedOtp);

    try {
      // Replace this with your API endpoint to send email
      await axios.post(`${apiBaseUrl}/send-email`, {
        email: forgetpass.email,
        otp: otp,
      });
      setMessage('OTP has been sent to your email');
    } catch (error) {
      setMessage('Failed to send OTP');
      console.error(error);
    }
  };

    return (
        <div className='Container_ForgotPass'>
            <div className='Container_ForgotPass_Vector'>
                <img className="Container_ForgotPass_Vector_sys" src={ForgotVector} alt="?" />
                <img className="Container_ForgotPass_Vector_mob" src={ForgotVectorMob} alt="?" />
            </div>
            <div className='Container_ForgotPass_Field'>
                <div className='Container_ForgotPass_Field_Fields'>
                    <h4>Forget Password</h4>
                    <span>we'll send you a link to reset your password.</span>
                    <input type="text" placeholder="Enter email here...." 
                    value={forgetpass.email}
                    onChange={(e) => handleEmail(e,'mail')}
                    />
                    <button onClick={handleSubmit}>Submit</button>
                    <Link className="link_to_login" to="/"><img src={Back} alt="<" />Back to login</Link>
                </div>
            </div>
        </div >
    )
}

export default ForgotPass
